// .sobre

.sobre__content
    padding-bottom 0

.sobre__subtitle
    color $cor-terciaria
    font-size 32px

@media $desktop-large
    .sobre__subtitle
        font-size 30px

@media $tablet-small
    .sobre__grupo
        display flex
        flex-direction column
        