.venda-de-terreno
    width 100%
    margin-bottom: 8rem
    display: flex
    overflow-x: hidden
    transition: 0.2s
    position: relative                                
    justify-content: center

    &__modal-wrapper
        width 100%
        height: 100%
        background: rgba(46, 46, 46, 0.308)
        position: fixed
        top: 0
        left: 0
        opacity 0
        padding: 10px
        z-index 100
        right 0
        transition: 0.4s
        bottom: 0
        pointer-events: none

        &__modal
            max-width 420px
            display: grid
            padding: 0px 10px 10px 10px
            height: 275px
            grid-template-rows: 30px 50px 120px 60px
            width 100%
            border-radius: 7px
            transition: 0.3s
            background: white
            position: fixed
            top: 50%
            left: 50%
            transform: translate(-50%, -25%)

            &__header
                border-radius: 7px
                display: flex
                justify-content: flex-end
                padding-top: 7px 

                &__close
                    width 20px
                    display: flex
                    justify-content: center
                    align-items: center
                    position relative
                    transform: translateY(10px)
                    height: 20px
                    cursor pointer

                    &__icon
                        width 18px
                        height: 2px
                        position: absolute
                        background: #BF5000
                        display: block

                    &__line-one
                        transform: rotate(47deg)

                    &__line-two
                        transform: rotate(-47deg)

            &__title-container
                max-width: 300px
                margin: auto

                &__title
                    text-align: center
                    font-size: 24px
                    width 100%
                    margin-top: 5px
                    color: #103E6F

            &__message-container
                text-align: center
                max-width: 340px
                font-size: 17px
                margin-top: 20px
                margin: auto

                &__container-subtitle
                    max-width: 290px
                    padding: 7px
                    margin: auto
                    margin-top: 10px
                    background: #F2F2F2

            &__container-button-loader
                display: flex
                justify-content: center
                width 100%
                margin: auto
                padding-bottom: 10px

                &__loader-container
                    width 100%
                    height: 50px

                    &__loader
                        width 100%
                        height: 100%
                        replace('spinner-blue.svg')
                        background-position: center

                &__button
                    width 148px
                    color: #D0782F
                    font-weight: 600
                    font-size: 18px
                    background: transparent
                    border: 2px solid #D0782F
                    border-radius: 30px
                    cursor pointer
                    height: 45px

    &__reference
        width 20px
        height: 20px
        position: absolute
        top: -30%

    &__card
        width 100%
        height: 100%
        overflow: hidden
        padding: 4rem 2rem
        padding-bottom: 2rem
        max-width: 700px
        border-radius: 10px
        background: white

        &__form
            position: relative
            display: grid
            transition: 0.5s
            align-content: end

            &__recaptcha
                margin-top: 0rem !important
                margin-bottom: 2.5rem

            &__spinner
                replace('spinner.svg')
                width 100%
                height: 100%
                opacity 0
                position: absolute
                background-position: center

                &--show
                    replace('spinner.svg')
                    width 100%
                    height: 100%
                    opacity 1
                    position: absolute
                    background-position: center

            &--hide-text
                color: #ffffff00

            &__step
                transition: 0.5s;
                opacity 0
                transition-behavior: allow-discrete;
                position: absolute
                pointer-events: none

            &__characteristics
                padding-bottom: 2rem
                padding-top: 2rem
                padding-left: 2rem

            &__terms-container
                margin-top: 2rem

                &__checkbox
                    display: flex

                &__label
                    font-weight: 900

                &__link
                    color: #103E6F

            &__consentimento
                padding-top: 0.5rem

            &__double-input
                margin-top: 3.5rem

            &__docs-images-container
                margin-bottom: 0rem

                &__preview-images-container
                    display: flex
                    margin-left: 35px
                    flex-wrap: wrap
                    gap: 5px

            &__separator
                position relative
                margin: 1.2rem 0rem

                &--large
                    margin: 2rem 0rem

                &--large-1x
                    margin-top: 3rem

            &__col-1fr-1fr
                display: grid
                gap: 1.3rem
                grid-template-columns: 1fr 1fr

            &__col-1fr-2fr
                display: grid
                gap: 4.3rem
                grid-template-columns: 1fr 2fr

            &__col-1fr-3fr
                display: grid
                gap: 1.3rem
                grid-template-columns: 1fr 3fr

            &__col-3fr-1fr
                display: grid
                gap: 1.3rem
                grid-template-columns: 3fr 1fr

            &__container-step-title
                display flex
                align-content: flex-end
                justify-content: space-between
                margin-top: 2rem

                &__step-indicator
                    align-self: end
                    font-weight: 900
                    padding-bottom: 5px
                    font-size: 1rem

            &__step-title
                font-size: 30px
                margin-top: 4rem
                font-weight: 100
                color: #103E6F
                margin-bottom: 2rem


                &--first
                    font-size: 30px
                    font-weight: 100
                    color: #103E6F

            &__alert
                font-size: 12px
                max-width: 450px
                padding: 1rem 0rem 0.8rem 0rem
                width 100%

            &__container-documents
                margin-top: 0

            &__document-name
                margin-left: 35px
                color: #103E6F
                font-weight: 900

            &__document-error
                margin-left: 35px

            &__container-images
                margin-bottom: 0px

            &__docs-label
                font-weight: 900
                display: flex
                cursor pointer
                gap: 5px
                flex-direction: column
                align-items: flex-start

                &__description-container
                    margin-left: 35px
                    max-width: 490px
                    width 100%
                    text-transform: uppercase

                &__button
                    height: 45px
                    width 171.18px
                    border: 1px solid #D0782F
                    border-radius: 30px
                    color: #D0782F
                    display: flex
                    text-transform: capitalize
                    align-items: center
                    justify-content: center
                    margin-top: 10px

                    &:hover
                        cursor pointer


                &__title-container
                    display: flex
                    align-items: center
                    gap: 5px
                    text-transform: uppercase

                &__icon-images
                    width 30px
                    height: 30px
                    transform: translateX(-5px)
                    replace('upload-images.svg')

                &__icon-document
                    width 30px
                    height: 30px
                    replace('upload-docs.svg')

                &__title
                    font-size: 16px

                &__warning
                    max-width: 490px
                    font-weight: 100
                    width 100%
                    text-transform: capitalize
                    line-height: 18px
                    font-size: 14px

            &__steps-container
                width: 100%
                margin-top: 3rem
                height: 40px
                display: flex
                justify-content: center

                &__steps
                    width 100%
                    display: flex
                    justify-content: space-between
                    height: 100%
                    gap: 10px

                    &__previous
                        cursor pointer
                        background: transparent
                        max-width 160px
                        width 100%
                        border: 2px solid #D0782F
                        color: #D0782F
                        font-size: 1rem
                        height: 43px
                        font-weight: 800
                        border-radius: 50px
                        
                    &__next
                        cursor pointer
                        background: #D0782F
                        max-width 160px
                        width 100%
                        color: white
                        font-size: 1rem
                        font-weight: 800
                        height: 43px
                        border-radius: 50px
                        border: 0px

                    &__progress
                        max-width: 100px
                        width 100%
                        display: flex
                        align-items: center
                        justify-content: space-around

                        &__bullet
                            width 13px
                            height: 13px
                            display: block
                            transition: 0.5s
                            background: #C7C7C7
                            border-radius: 100%

                            &--active
                                color: #BF5000

            & input, select, textarea
                border 1px solid $cinza-400

                &:focus
                    border 1px solid $cor-secundaria

            & input[type="file"]
                width 0
                opacity 0
                pointer-events: none
                overflow hidden
                height: 0

            & input[type='checkbox']
                -webkit-appearance: none;
                appearance: none;
                background-color: #fff;
                margin-left: 5px;
                font: inherit;
                color: #666666;
                cursor pointer
                width: 1.15em;
                height: 1.15em;
                border: 0.15em solid #666666;
                border-radius: 0.25em;
                transform: translateY(-0.3em);
                display: grid;
                place-content: center;

            & input[type="checkbox"]::before
                content: "";
                width: 0.65em;
                margin-bottom: 1.1px
                height: 0.65em;
                background: #103E6F
                transform: scale(0) rotate(2deg);
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
                transform-origin: bottom left;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);

            & input[type="checkbox"]:checked::before
                transform: scale(1)

            & textarea
                margin-top: 1.5rem

.active-bullet-step
    background: #BF5000
    transition: 0.5s

.active-step
    opacity 1
    top: 0
    position: relative
    transition: 0.5s;
    transition-behavior: allow-discrete;
    pointer-events: all

.active-image-preview
    position: relative
    object-fit: cover
    width 75px
    height: 75px
    border-radius: 10px

.active-wrapper
    position: relative
    width 75px
    height: 75px

.active-wrapper-preview
    position: absolute
    z-index 10
    border-radius: 10px
    top: 0
    bottom: 3px
    left: 0
    right 0
    replace('close-icon.svg')
    background-size: 20px
    padding: 5px
    background-position: 95% 8%
    opacity 1
    cursor pointer
    pointer-events: all

.active-modal-wrapper
    pointer-events: all
    opacity 1
    transition: 0.4s

.active-modal
    transition: 0.3s
    transform: translate(-50%, -50%)

.hide-button
    opacity 0
    pointer-events: none

@media $mobile-large
    .venda-de-terreno
        &__card
            &__form
                &__col-1fr-1fr
                    grid-template-columns: 1fr

                &__col-1fr-2fr
                    grid-template-columns: 1fr

                &__col-1fr-3fr
                    grid-template-columns: 1fr

                &__col-3fr-1fr
                    grid-template-columns: 3fr

                &__characteristics
                    padding-left: 0px

                &__docs-images-container
                    &__preview-images-container
                        margin-left: 0px
                        margin-top: 5px

                &__document-name
                    margin-left: 0px

                &__document-error
                    margin-left: 0px

                &__docs-label

                    &__description-container
                        margin-left: 0px
                    
                    &__title
                        font-size: 16px

                    &__warning
                        font-size: 14px

                &__step-title
                    font-size: 24px

                    &--first
                        font-size: 24px


                