.trabalhe__cards
    display flex
    flex-wrap wrap
    justify-items left
    grid-gap 2rem

    &--flex
        display flex
        flex-direction column
        align-items center
        justify-content center

.cards__content
    font-family 'Poppins'
    height 425px
    width 340px
    box-sizing border-box
    background-color #0077ee
    box-shadow rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px
    border-radius 6px
    cursor pointer
    transition all .3s

    img
        max-width 340px
        max-height 425px
        width auto
        height auto
        border-radius 6px

    &:hover
        transform scale(1.02)

    &--trabalhe
        display flex
        flex-direction column
        align-items center
        justify-content center

.cards__clipboard
    margin-bottom 7px

.cards__title--trabalhe
    font-size 24px
    font-weight 700
    line-height 28px
    text-align center
    color #fff
    margin-bottom 30px
    width 80%

.cards__subtitle
    width 80%
    font-size 18px
    font-weight 400
    line-height 24px
    text-align center
    color #fff
    margin-bottom 22px

.cards__button
    text-decoration none
    font-size 16px
    font-weight 800
    color #fff
    background-color #88bc43
    text-align center
    width 210px
    padding 16px 0
    border-radius 55px
    transition all .3s

    &:hover
        color #fff
        background-color #efb71e

.cards__loading
    display flex
    align-items flex-start
    justify-content center
    min-height 400px
    width 100%
    padding-top 60px
    
    span
        font-size 28px
        font-weight 700
        color $cinza-400
        animation loading-opacity 600ms linear infinite

.search__vagas
    display flex
    align-items center
    justify-content center
    min-height 120px
    width 100%
    padding-top 40px

    span
        font-family 'Poppins'
        color $cinza-700
        text-align center
        font-size 18px
        font-weight 500


@keyframes loading-opacity
    50% 
        opacity 0.7
    100%
        opacity 1

@media $tablet-medium
    .trabalhe__cards
        justify-content center

@media $mobile-large 
    .trabalhe__cards
        flex-wrap nowrap
        flex-direction column
        align-items center
        justify-items center

@media $mobile-medium
    .cards__content
        max-width 100%

        img
            max-width 100%

    .cards__content--trabalhe
            max-width 100%

@media $mobile-small
    .cards__content
        height auto

        &:last-child
            height 380px
            