.geracao
    padding 0 0 8em 0
    bg('geracao-bg.svg', center)

.geracao__container
    display flex
    align-items center
    justify-content space-between

.geracao__header
    position relative
    display flex
    align-items flex-end
    flex-direction column
    width 48%
    margin-right 5%

.geracao__title
    display none
    
.geracao__foto
    display flex
    margin-right 25px
    
.geracao__description
    max-width 320px
    margin-top -100px
    line-height 1.8
    z-index 1

.geracao__body
    position relative
    display flex
    flex-direction column
    align-items flex-end
    max-width 640px

.geracao__video
    position relative
    grid-area video
    grid-column 2/4
    grid-row 1/2
    margin-top 90px

.geracao__thumb
    border-radius 8px
    overflow hidden

.geracao__img
    display block
    width 100%
    height 100%
    object-fit cover

.geracao__play
    position absolute
    top calc(50% - 25px)
    left calc(50% - 25px)
    square 50px
    display block

    &:before
        content ''
        position absolute
        display block
        square 50px
        center()
        border-radius 50px
        background $branco
        transition-duration .3s
    
    &:after
        content ''
        position absolute 
        size 20px 30px
        center(5px, 5px)
        replace('ico-play.svg')
        transition-duration .3s

    em
        position absolute
        display inline-block
        left 240px
        top 4px
        color $branco
        padding 14px 22px
        font-style normal
        font-size 12px
        letter-spacing 6px
        text-transform uppercase
        background $verde  
        transition-duration .3s

        &:before
            content ''
            position absolute
            top 50%
            right 147px
            width 200px
            height 1px
            background alpha($branco, 50%)

.geracao__link
    &:hover
        em
            color $verde
            background $branco
            transition-duration .3s

        .geracao__play
            &:before
                content ''
                position absolute
                display block
                square 55px
                center()
                border-radius 55px
                transition-duration .3s
            
            &:after
                content ''
                position absolute 
                size 20px 30px
                center(5px, 5px)
                replace('ico-play.svg')
                transition-duration .3s

.geracao__vejamais
    margin-top 30px

    &:after
        content ''
        size 30px 21px
        margin-left 10px
        replace('ico-youtube.png')

@media $desktop-small
    .geracao__play em
        left 200px
    
    .geracao__container
        display grid
        grid-template-columns 1fr 1fr
    
    .geracao__header
        justify-content center
        align-items center
        width 80%

    .geracao__description
        max-width none
        margin-top -110px

@media $tablet-large
    .geracao__play em
        left 120px

    .geracao__play em::before 
        right 146px
        width 80px

    .geracao__description
        margin-top -110px

@media $tablet-medium
    .geracao__play em
        display none

    .geracao__description
        margin-top -145px 

@media $tablet-small
    .geracao
        background none

    .geracao__container
        display flex
        flex-direction column
    
    .geracao__video
        margin-top 2em
    
    .geracao__header
        width 90%
    
    .geracao__vejamais
        width 100%
 
    .geracao__description
        margin-top -110px