@keyframes float-1 {
    to {
        transform translateY(50px)
    }
}

@keyframes float-2 {
    to {
        transform translateY(-30px)
    }
}
