.noticia__content
    padding-bottom 0

.noticia__subtitle
    line-height 1.2
    // margin-bottom 1.5em
    font-size 32px
    color $cor-terciaria
    
.noticia__intro
    margin 2em 0
    font-size 22px
    color $cinza-700
    line-height 1.4
    font-weight 400

.noticia__meta
    display block
    font-size 14px
    color $cinza-400

.noticia__container
    max-width 760px
    margin-bottom 8em

    img
        margin-bottom 5em

.noticia__detail
    display flex
    justify-content space-between
    align-items center
    border-top 1px solid $cinza-200
    margin 0 0 5em
    padding-top 15px

.noticia__main
    font-size 20px
    line-height 1.8 !important
    margin-bottom calc(110px + 8em)
    color $cinza-700 !important

    img
        max-width 100%

    *
        font-family inherit !important

.noticia__main
    p
        @extend .noticia__main
        margin-bottom 0

.noticias__relacionadas
    position relative
    background-color $cinza-200
    margin-top 110px
    padding 2em 0 5em 0

    &:before
        content ''
        position absolute
        top -109px
        left 0
        width 100%
        height 110px
        replace('relacionadas-bg.svg', center)
        background-size cover
    
    .news__list
        grid-template-columns 1fr 1fr

    .news__title:before
        content ''
        display block
        width 8px 
        height 28px
        margin-right 15px
        margin-top -2px
        background $cor-primaria

    .news__title
        display flex
        font-weight 700
        text-transform none
        margin-bottom 1.5em
        font-size 32px
        color $cor-terciaria
        letter-spacing normal
    
    .news__action
        margin-top 3em

@media $tablet-small
    .noticia__subtitle
        font-size 28px
        line-height 1.1
    
    .noticia__intro
        font-size 18px

    .noticia__detail
        flex-direction column
        align-items flex-start

@media $mobile-large
    .noticia__subtitle
        font-size 24px
        
    .noticia__intro
        font-size 16px