.clients
    display flex
    align-items center
    margin-top 10em

.clients__mobile
    display none

.clients__title
    display block
    max-width 200px
    grid-column 1/2
    line-height 1
    margin-right 4em
    font-size 30px
    text-transform uppercase
    color $branco
    font-weight 400

    strong
        font-weight 700

.clients__wrapper
    width 100%
    padding 20px
    border-radius 4px
    background-color $branco
    box-shadow 0 14px 40px rgba(0, 0, 0, 0.15)
    overflow hidden
    display flex
    justify-content flex-start
    align-items center
    color $preto

.clients__button--next, 
.clients__button--prev
    color $verdeescuro !important
    background-color $branco
    position absolute
    height 65px !important
    top 30% !important
    width 40px !important

    
    &:after
        font-size 12px !important
        font-weight bold !important

.clients__button--prev
    border-right 1px solid $cinza-300
    left -15px !important

.clients__button--next
    border-left 1px solid $cinza-300
    right -15px !important

.swiper.clients__swiper
    padding 16px 0px

.clients__list
    position relative
    display flex
    align-items center

    // .swiper-wrapper
    //     display flex
    //     justify-content space-around
    //     align-items center

.clients__item
    display flex
    justify-content center
    align-items center

    img
        display block
        object-fit contain
        max-width 70%
        height 40px

@media $tablet-large
    .clients
        flex-direction column
        align-items flex-start

    .clients__title 
        margin-bottom 1em

@media $mobile-large
    .clients__title
        display none

    .clients__wrapper
        display none

    .clients__mobile
        display flex
        flex-direction column
        width 100%
        border-radius 10px
        padding 40px
        background-color $branco
        position relative
    
        .clients__title
            display block
            color $cor-terciaria
            margin-left 2rem
            line-height 1.2

            &:before
                content ''
                position absolute
                left 0
                width 18px
                height 32px
                background-color $cor-primaria

        .clients__logos
            .clients__list
                display grid
                grid-template-columns repeat(2, 1fr)
                grid-gap 2rem
