

.cookies__content
  border 1px solid $preto !important
  border-top 1rem solid $cor-primaria !important

.cookies__header
  padding-top 3rem !important
  position relative
  &:after
    position absolute
    top 0
    content ''
    bg('cookie-icon.svg')
    square 36px


.cookies__title
  color $cor-primaria !important
  font-size 36px !important

.cookies__actions
  display flex
  flex-direction column
  align-items center
  justify-content center
  gap 1rem

.cookies__btn
  height 50px
  min-width 42px
  padding 1rem
  gap 1rem
  border 2px solid transparent
  box-sizing border-box
  font-family $font-primaria
  font-size 16px
  font-weight 600
  line-height 1
  letter-spacing 0
  text-align center
  text-transform none
  cursor pointer
  transition all 150ms ease
  width 75%
  min-width 200px
  border-radius 50px

.cookies__confirm
  background $cor-primaria
  color #fff
  &:hover,
  &:focus
      color $branco
      background $cor-secundaria

.cookies__cancel
  color $cor-secundaria
  border 2px solid $cor-primaria
  box-shadow none
  background transparent
  &:hover,
  &:focus
      border 2px solid $cor-secundaria
      color $branco
      background $cor-secundaria

@media $tablet-large
  .cookies__btn
      height 42px
      font-size 14px