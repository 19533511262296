// Colors
$cinza-100 = #f9f9f9
$cinza-200 = #EFEFEF
$cinza-300 = #DCDCDC
$cinza-400 = #C4C4C4
$cinza-500 = #666666
$cinza-600 = #555555
$cinza-700 = #333333
$cinza-800 = #222222
$cinza-900 = #111111

$cinza-erro = #4B5960
$cinza-card = #F3F3F3

$preto       = #000000
$branco      = #ffffff

$cor-sucesso = #88BC43
$cor-erro    = #F23239
$bg-erro    = #ffd9d9

$turquesa       = #42c2bb
$ciano          = #75bbe7
$ciano-claro    = #BBD9F0
$azul-claro     = #406B98
$azul           = #103E6F
$azul-escuro1   = #052F5C
$azul-escuro2   = #002041
$roxo           = #2D307A
$amarelo        = #FFE37E
$laranja        = #D0782F
$laranja-escuro = #BF5000
$verdeescuro    = #086c3a
$verde          = #4EA646
$verdeclaro     = #C4EDB1
$verdeesmeralda = #42C2BB
$vermelho-enova-terrenos = #d64247
$vermelho-erro-orcamento = #ff4e4e
$vermelho-antes-conta = #E25656

$cor-primaria = $laranja
$cor-secundaria = $azul
$cor-terciaria = $azul-escuro2

// Animation
$time-short        = 150ms
$time              = 300ms
$time-long         = 500ms

$smooth = cubic-bezier(1,.01,.05,1)
$smooth2 = cubic-bezier(.5,0,.5,1)
$smooth3 = cubic-bezier(.57,.02,.23,.99)
$smooth4 = cubic-bezier(0.250, 0.460, 0.450, 0.940)

:root
  --level-10 100
  --level-20 200
  --level-30 300
  --level-40 400
  --level-50 500
  --header-width 270px
  --header-height 160px
  --footer-height 95px

@media $tablet-large
  :root
    --header-height 150px
