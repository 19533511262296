
.valores
    display flex
    flex-direction column
    margin-bottom 10em

.valores__title
    margin-bottom 1.5em

.valores__intro
    max-width 520px
    font-size 14px
    line-height 2
    margin-bottom 5em

.valores__nome
    font-weight 500
    font-size 18px

.valores__list
    position relative
    display grid
    grid-template-columns 1fr 1fr 1fr
    grid-column-gap  5em
    grid-row-gap  2em

    &:before
        content ''
        grid-column 1/2
        grid-row 1/3
        size 429px 436px
        replace('ilu-valores.svg')

.valores__header
    display flex
    flex-direction row-reverse
    align-items center
    margin-bottom 1em

.valores__item
    display flex
    align-items flex-start
    justify-content flex-start
    flex-direction column
    margin-bottom 1em
    line-height 1.6

.valores__description
    max-width 370px
    font-size 14px
    line-height 2
    
.valores__icon
    margin-right 15px

.valores__img
    display block

@media $tablet-large
    .sobre__valores
        margin-top 5em

    .valores__list
        grid-template-columns 1fr 1fr

        &:before
            width 100%
            height auto
            background-size contain

@media $tablet-medium
    .sobre__valores
        margin-bottom 5em
    


@media $tablet-small
    .valores__list
        display flex
        flex-direction column

    .valores__list::before
        height 300px
    
    .valores__img
        max-width 50px