//  vendor
@require '../../node_modules/sweetalert2/dist/sweetalert2.min.css';
@require '../../node_modules/swiper/swiper-bundle.min.css';


@require 'base/reset'
@require 'base/media'
@require 'base/variables'
@require 'base/media'
@require 'base/mixins'
@require 'base/base'
@require 'base/font-awesome'
@require 'base/fonts'
@require 'base/typography'
@require 'base/animations'

@require 'components/*'
@require 'pages/*/*'
