.sitemap
    width 100%
    margin-left 8vw

.sitemap__wrap
    width 100%
    display flex
    justify-content space-between

.sitemap__group
    display flex
    flex-direction column

.sitemap__link
    display flex
    align-items center
    justify-content flex-start
    padding 4px 0
    color $cor-primaria
    font-size 14px
    font-weight 700
    text-decoration none

    &:hover
        color $cor-terciaria
        text-decoration underline

.sitemap__label
    position relative
    height 30px
    margin-bottom 20px
    text-transform uppercase
    text-decoration none
    font-size 14px
    font-weight 700
    color $cinza-400
    
    &:after
        content ''
        position absolute
        bottom 0
        display block
        width 25px
        height 2px
        background-color $cor-secundaria

@media $desktop-medium
    .sitemap
        margin-left 4vw

    .sitemap__wrap    
        display grid
        grid-template-columns 1fr 1fr 1fr
        grid-gap 4vw