.solucoes
    padding 8em 0
    color $branco
    bg('solucoes-bg.svg', center, 0)
    background-size 100% auto
    background-color $cinza-100

.solucoes__title
    display block
    margin-bottom 2em
    text-transform uppercase
    font-size 9px
    letter-spacing 2px
    font-weight 400

.solucoes__subtitle
    margin-bottom .75em
    font-size 50px
    font-weight 700

.solucoes__introducao
    margin-bottom 5em
    max-width 700px
    line-height 1.8
    font-size 16px

.solucoes__list
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 20px

.solucoes__item
    display flex
    align-items flex-start
    justify-content flex-end
    flex-direction column
    padding 40px 70px 50px
    border-radius 10px

.solucoes__item:nth-child(1)
    background $cor-primaria
    
.solucoes__item:nth-child(2)
    background $turquesa

.solucoes__item:nth-child(3)
    background $laranja

.solucoes__thumb
    display flex
    justify-content center
    width 100%
    margin-top -70px
    margin-bottom 2em

.solucoes__img
    display block
    max-width 100%
    max-height 310px

.solucoes__label
    font-size 40px
    font-weight 400

    strong 
        display block
        font-weight 700
    
.solucoes__description
    max-width 85%
    margin 1.5em 0 3em
    font-size 14px
    line-height 1.8

@media $desktop-large
    .solucoes__item
        padding 30px 50px 50px

    .solucoes__subtitle
        font-size 40px

    .solucoes__label
        font-size 32px

@media $desktop-small
    .solucoes__label
        font-size 26px
    
    .solucoes__item
        padding 30px 40px 40px

@media $tablet-large
    .solucoes__subtitle
        font-size 32px
        
    .solucoes__label
        font-size 24px

    .solucoes__item
        padding 30px
    
    .solucoes__introducao
        max-width 540px
    
    .solucoes__img
        max-width 80%

@media $tablet-medium
    .solucoes
        background-position center bottom
        background-color $cor-terciaria
    
    .solucoes__list
        display flex
        flex-direction column
        grid-template-columns 1fr
        
    .solucoes__item
        margin-bottom 3em

    .solucoes__img
        max-width 260px

@media $tablet-small
    .solucoes__subtitle
        font-size 36px
        max-width 300px
    
    .solucoes__img
        max-width 200px

@media $mobile-medium
    .solucoes__description
        max-width 95%
    
    .solucoes__thumb
        margin-bottom 1em