.grupo-canopus
    display flex
    flex-direction column
    margin-bottom 2rem
.grupo-canopus-page__section-titulo
    font-size 2.5rem
    font-weight 400
    line-height  2.5rem
    letter-spacing -0.05rem
    color $cor-secundaria
    width 40%
.grupo-canopus__header, .grupo-canopus__valoresvisao, .grupo-canopus__valorizacao, .grupo-canopus__parceiros
    margin-bottom 4rem

.grupo-canopus__header
    display grid
    grid-template-columns 1fr 1fr
    &-texto
        display flex
        flex-direction column
        gap 2rem
        width 80%
        color $cinza-500
        line-height 1.5rem
        &>h2
            width 100%
    &>img
        justify-self: end
        width 90%
        border-radius 10px

.grupo-canopus__valoresvisao
    display flex
    justify-content center
    gap 2rem
    text-align justify
    &-titulo
        color $cor-secundaria
        font-size 2.5rem
        font-weight 400
        line-height 2.7375rem
    &-item
        padding 2rem
        border 1px solid $cinza-300
        border-radius 1.25rem
        display flex
        flex-direction column
        gap 1rem
        min-height 15rem
        width 50%
        &>p
            color $cinza-500
            font-size 1rem
            font-weight 400
            line-height 1.5rem
            width 90%
        &>ul
            display flex
            flex-direction column
            gap 1rem
            list-style-type circle

.grupo-canopus__nossosnumeros
    position relative
    top 4rem
    z-index 1
    width 92%
    margin 0 auto
    padding 6rem 10rem
    display grid
    grid-template-columns 1fr
    gap 2rem
    background $branco
    border-radius 1.25rem
    &>p
        text-align justify
        width 38%
        color $cinza-500
        font-size 1rem
        font-weight 400
        line-height 1.5rem
        letter-spacing -0.05rem
    &-estatistica
        display grid
        grid-template-columns 1fr 1fr 1fr
        margin-top 2rem
        gap 6rem
        &>span
            display inline-flex
            flex-direction column
            color $cor-secundaria
            font-size 1.625rem
            font-weight 600
            max-width 25rem 
        &>span>b
            color $cor-primaria
            font-size 2.5rem
            font-weight 800

.grupo-canopus__valorizacao
    position relative
    top -5rem
    width 100%
    padding 6rem 4.5rem
    padding-top 20rem
    display grid
    grid-template-columns 1fr
    gap 4rem
    bg('galeria/galeria1.png')
    background-size cover
    background-blend-mode overlay
    z-index 0

    &-bg
        background-color rgba(15, 60, 108, 0.74)
        backdrop-filter blur(7px)
        height 100%
        width 100%
        position absolute
        z-index -1

    &-header
        display grid
        grid-template-columns 1fr 1fr
        >.grupo-canopus-page__section-titulo
            color $branco
            width 75%

    &-descricao
        display flex
        flex-direction column
        &>p
            color $branco
            line-height 1.5rem

    &-lista
        display grid
        grid-template-columns: repeat(5, 1fr)

    &-item
        display inline-flex
        flex-direction column
        gap 1rem
        width min-content
        color $branco
        text-align center
    &-descricao>p
            text-align justify

.grupo-canopus__parceiros
    display flex
    align-items center
    justify-content center
    flex-direction column
    text-align center
    gap 4rem
    .grupo-canopus-page__section-titulo
        width 100%
    &-list
        display flex
        flex-wrap wrap
        justify-content center
        gap 4rem
    &-item
        display flex
        align-items center
        justify-content flex-start
        flex-direction column
        gap 1rem
        width 15rem
        min-height 10rem
        text-align center
        &>img
            width 10rem
            height 5rem
            object-fit contain
        & :last-child
            margin-top auto
            border-top 1px solid $cinza-400
            padding 0.5rem

@media $desktop-medium
    .grupo-canopus__nossosnumeros
        padding 6rem 4rem

@media $desktop-small
    .grupo-canopus__valoresvisao
        flex-direction column
        align-items flex-start
        gap 2rem
        &-item
            width 90%
            min-height fit-content
    
@media $tablet-large
    .grupo-canopus-page__section-titulo, .grupo-canopus__nossosnumeros>p
        width 75%

@media $tablet-medium
    .grupo-canopus__header
        grid-template-columns 1fr
        place-items start center
        gap 2rem
        &>img
            justify-self center
            height auto
            width 80%
    
    .grupo-canopus__nossosnumeros
        &>p
            width 100%
        &-estatistica
            grid-template-columns repeat(2, 1fr)
    
    .grupo-canopus__valorizacao
        &-header
            gap 2rem
            grid-template-columns 1fr
        &-lista
            display flex
            flex-wrap wrap
            gap 4rem
            justify-content center

@media $mobile-large
    .grupo-canopus__header-texto, .grupo-canopus__header-img, .grupo-canopus__valoresvisao-item
            width 100%
    
    .grupo-canopus__header
        &>img
            width 95%

    .grupo-canopus-page__section-titulo
        font-size 2rem
        width 100%
    .grupo-canopus__nossosnumeros
        padding 2rem
        &-estatistica
            grid-template-columns 1fr
    .grupo-canopus__valorizacao
        padding 15rem 1rem 6rem
        &-header>.grupo-canopus-page__section-titulo
            width 100%
        &-lista
            display none
        &-estatistica
            &>span
                font-size 1.25rem
            &>span>b
                font-size 1.875rem
    .grupo-canopus__nossosnumeros-estatistica
        gap 2rem
    .grupo-canopus__valoresvisao-titulo
        font-size 1.625rem