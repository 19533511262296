.overlay
    position fixed
    left 0
    top 0
    width 100%
    height 100%
    background-color alpha($preto, 0.5)
    z-index 5
    opacity 0
    pointer-events none
    transition opacity 300ms ease
    
.overlay--active
    opacity 1
    pointer-events all