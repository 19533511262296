.orcamento
    padding 8em 0
    bg('orcamento-bg.jpg')
    background-size cover
    color $branco
    // transition all 500ms ease

    .input__label
        color $branco
    
    .input__field
        border-color $branco

.orcamento__container
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 50px

.orcamento__title
    margin-bottom 1.2em
    color $branco
    line-height 1.1
    font-weight 400
    font-size 30px

    strong
        display block
        font-weight 800
        color $cor-secundaria
        font-size 50px
    
.orcamento__intro
    max-width 340px
    line-height 1.6

.orcamento__destaque
    display block
    margin 3em 0 1em
    border-left 3px solid $cor-primaria
    padding-left 15px

.orcamento__form
    max-width 680px

.orcamento__subtitle
    display block
    margin-bottom 3em
    font-size 18px
    color $branco
    font-weight 400

.orcamento__etapa
    display none

.orcamento__etapa--current
    display block
    animation slide-right 0.5s $smooth4 both

.orcamento__steps
    display flex
    align-items center
    margin-bottom 3em

.orcamento__step
    position relative
    display flex
    align-items center
    square 12px
    border-radius 15px
    margin 0 70px 0 0
    background-color $cinza-300

    &:after
        content ''
        position absolute
        top 50%
        left 100%
        display block
        width 70px
        height 1px
        background-color $cinza-300

    span
        display none

.orcamento__step--current
    background-color $cor-primaria

    &:before
        content ''
        square 16px
        center(-1px, -1px)
        border-radius 10px
        border 1px solid $cor-primaria

    &:after
        left calc(100% + 3px)

.orcamento__step--done
    background-color $cor-primaria
    
    &:after
        left 100%
        background-color $cor-primaria

.orcamento__step:last-child
    display flex
    align-items center
    justify-content center
    square 24px

    &:before
        content ''
        size 21px 8px
        replace('check.svg')
        background-size 21px 8px
        filter grayscale(1)

.orcamento__step:last-child:after
    display none

.orcamento__form
    .form__footer
        padding 2em 0 0
        margin-top 3em
        border-top 1px solid alpha($branco, 50%)
        position relative

        .erro_orcamento
            margin-top 0

    .form__footer--hidden, .orcamento__prev--hidden
        visibility hidden

.orcamento__next
    margin-left auto

.orcamento__radio.radio.input__label
    justify-content center
    flex-direction row-reverse
    width 100%
    height 150px
    margin-bottom 0
    border-radius 10px
    font-weight 600
    font-size 16px
    color $cor-primaria
    background-color $branco

    .radio__mark
        square 100%

    .radio__mark:after
        top -4px
        left -4px
        width calc(100% + 2px)
        height calc(100% + 2px)
        border-radius 12px
        border 4px solid $cor-primaria
        background transparent
        transform none

.orcamento__radio.radio.input__label:hover .radio__mark::after
    border-color $cor-secundaria
    opacity 1

.erro_orcamento
    font-size 11px
    margin-top -0.5rem
    color $branco
    background $vermelho-erro-orcamento
    border 0.2rem solid $vermelho-erro-orcamento
    border-radius 50px
    padding 2px 6px

.orcamento__setor
    filter grayscale(1) brightness(120%)
    transition all 300ms ease

.orcamento__row
    .has-error
        .input__field
            border-color $vermelho-erro-orcamento

.radio__field:checked ~ .orcamento__icon
    .orcamento__setor
        filter grayscale(0) brightness(100%)

.orcamento__icon
    width 60px
    margin-right 30px

.orcamento__etapa--3
    .orcamento__row
        margin-bottom 0

    .orcamento__row:last-child
        .input--checkbox .input__label
            margin-top 0

    .radio
        transition background 150ms linear

        &:hover
            background-color alpha(#222, .35)

    .radio.input__label
        font-size 16px
        padding-left 32px
    
    .radio__mark
        top auto
        left 10px
        square 22px
    
    .radio__mark:after
        top 2px 
        left 2px
        square 14px
    
    .input--checkbox .input__label::before
        square 22px
        border-color alpha($branco, 50%)

.orcamento__etapa--4
    position relative
    padding-left 110px
    max-width 480px

.orcamento__pronto + p
    font-size 20px
    line-height 1.5
    font-weight 400

.orcamento__pronto
    margin-bottom .75em
    font-size 42px
    font-weight 800

    &:before
        content ''
        position absolute
        top -20px
        left 0
        square 75px
        border-radius 50px
        replace('check.svg')
        background-color $cor-primaria
    
    &:after
        content ''
        position absolute
        top -20px
        left 0
        square 75px
        replace('check.svg', center, center)
        background-size 24px auto
        filter brightness(0) invert(1)
    
.orcamento__ok
    min-width 160px
    margin-top 2em

@keyframes slide-right
  0%
    transform translateX(100px)
    opacity 0

  100%
    transform translateX(0)
    opacity 1

@media $tablet-small
    .orcamento
        padding 6em 0
        
    .orcamento__container
        display flex
        flex-direction column
        
@media $mobile-medium
    .orcamento__title
        font-size 24px

        strong
            font-size 36px