.logo
    // display inline-flex
    size 218px 49px
    replace('logo.png')
    background-size contain
    transition all .2s ease

    .logo__link
        square 100%
        display block

.logo--large
    height 110px
    width 140px
    bgi('logo-white.png')
    background-size contain

.logo--equatorial
    size 200px 50px
    bgi('logo-equatorial-white.png')
    background-size contain

.theme-light .logo
    bgi('logo-color.png')

@media $tablet-large
    .logo
        size 170px 50px
    .logo--large
        height 80px
        width 100px