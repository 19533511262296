.contato-page
    overflow hidden
.contato
    display grid
    grid-template-columns 1fr 1fr
    place-items start center
    gap 2rem
    margin-bottom 4rem

    &__forms
        display flex
        flex-direction column
        gap 4rem
        &>h2
            color $cinza-500
            font-size 1rem
            font-weight 400
            line-height 1.5rem
            width 75%
    &__unidades
        position relative
        display flex
        flex-direction column
        gap 2rem
        width 80%
        margin 0 auto
        &>h2
            font-size 2.5rem
            font-weight 800
            line-height 2.5rem
            color $cor-secundaria
            
.contato__unidades-endereco 
    flex-wrap wrap
    align-items center
    row-gap 0.5rem
    &:before
        width 25px
    &> :first-child
        flex 0 1 80%
        
.contato__form-container
    display flex
    flex-direction column
    gap 1rem
    & :nth-child(3)
        width 50%

.contato__unidades
    &-img
        position absolute
        top 3rem
        right -6rem
        width 42%
        overflow hidden
        &>img
            position relative
        &:after
            position absolute
            right -90%
            content ''
            width 138%
            height 100%
            background $cor-primaria
            border-radius 80px
    &-lista
        width 100%
        display flex
        flex-direction column
        gap 1rem
    &-item
        background $branco
        padding 1rem 2rem
        max-width 100%
        grid-template-columns 1fr 1fr
        row-gap 1rem
        place-items center start
        border-radius 10px

.contato__localizacao
    &-cidade
        grid-column 2 span
        color $cor-secundaria
    &-telefone
        align-self flex-start
        justify-self flex-end
        color $cor-primaria
        font-weight 600
        &:before
            margin-right 8px

@media $desktop-large
    .contato__unidades-img
        width 48%
        &>img
            width 55%
        &:after
            width 130%
            right -80%

@media $desktop-small
    .contato__unidades-endereco, .contato__localizacao-telefone
        grid-column 2 span
    .contato__localizacao-telefone
        justify-self flex-start

@media $tablet-small
    .contato__unidades-img
        display none
    .contato
        grid-template-columns 1fr
        place-items start
    .contato__forms, .contato__unidades
        width 100%