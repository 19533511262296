.solucoes360
    padding 0 0 10em 0
    bg('solucoes360-bg.svg', center, 0)
    background-size 100% auto
    background-color $cor-secundaria

.solucoes360__container
    position relative
    display flex
    border-radius 20px
    padding 6em 6em 0 0
    background-color $cinza-200

// .solucoes360__header

.solucoes360__foto
    display block

.solucoes360__title
    position absolute
    top 50px
    left 0
    border-left 4px solid $amarelo
    padding-left 50px
    color $verde
    font-weight 400
    font-size 30px
    line-height 1.1

    strong
        display block
        font-weight 800
    
.solucoes360__subtitle
    max-width 360px
    margin-bottom 1em
    font-size 32px
    font-weight 400
    color $cinza-600

    strong
        font-weight 800
    
.solucoes360__action
    margin-top 4em

.solucoes360__action--mobile
    display none

.solucoes360__intro 
    max-width 440px
    width 100%
    padding-right 6em
    margin-bottom 6em
    border-right 1px solid lighten($cinza-300, 50%)

.solucoes360__intro p
    line-height 2
    font-size 16px
    color $cinza-600

.solucoes360__label
    margin-bottom .7em
    font-size 24px
    font-weight 800
    color $cor-terciaria

.solucoes360__item
    position relative
    margin-bottom 3em
    padding-left 150px

.solucoes360__item:before
    content ''
    position absolute
    top -20px
    left 0
    square 130px
    margin-right 50px

.solucoes360__item--equipe:before
    replace('ilu-capacete.png')

.solucoes360__item--instalacao:before
    replace('ilu-toolbox.png')

.solucoes360__item p
    line-height 2
    font-size 14px
    color $cinza-600

.solucoes360__aside
    padding 0 3em 0 5em

@media $desktop-large
    .solucoes360__subtitle
        font-size 22px
        margin-bottom 2em

    .solucoes360__label
        font-size 18px
    
    .solucoes360__aside
        padding 0 0 0 3em
    
    .solucoes360__intro
        padding-right 3em
    
    .solucoes360__foto
        width 100%
        margin-top 5em

    .solucoes360__container
        display grid
        grid-template-columns .85fr .95fr 1.2fr
        // grid-template-columns 0.95fr 0.75fr 1fr
        padding 4em 6em 0 0

    .solucoes360__header
        display flex
        align-items flex-end
        
    .solucoes360__intro p
        font-size 14px
    
@media $tablet-large
    .solucoes360__item
        display flex
        flex-direction column
        padding-left 0
        
    .solucoes360__item::before 
        position static
        size 100px 75px
        background-size contain
    
    .solucoes360__container
        grid-template-columns 1fr 1fr
        padding 150px 80px 50px 50px
    
    .solucoes360__intro
        grid-row 1/2
        grid-column 1/2

    .solucoes360__header
        grid-row 2/3
        grid-column 1/2
    
    .solucoes360__aside
        grid-column 2/3
        grid-row 1/3
    
    .solucoes360__foto
        margin-left -50px
        margin-bottom -50px
        margin-top 1em

    .solucoes360__intro
        margin-bottom 3em
        border-right 0
    
    .solucoes360__action
        margin-top 2em
    
    .solucoes360__subtitle
        line-height 1.2
        max-width 260px
    
@media $tablet-small
    .solucoes360
        padding-bottom 8em
        background-position center -1px

    .solucoes360__container
        display flex
        flex-direction column
        padding 8em 30px 4em
    
    .solucoes360__header
        flex-direction column
        align-items flex-start
        order 2
    
    .solucoes360__intro
        order 1
        margin-top 2em

        .solucoes360__action
            display none  
    
    .solucoes360__aside
        order 3
        padding-left 0
        margin-top 4em

        .solucoes360__action--mobile
            display flex  
        
    .solucoes360__title
        // position static
        padding-left 30px
        left 30px
    
    .solucoes360__subtitle
        font-size 28px
        max-width none
        margin-bottom 2em
    
    .solucoes360__label
        margin-top .5em
        font-size 22px
        line-height 1.2

    .solucoes360__foto
        margin-left 0
        margin-bottom 0

@media $mobile-medium
    .solucoes360__intro
        padding-right 0
    
    .solucoes360__subtitle
        margin-bottom 1em
        font-size 24px
    
    .solucoes360__title
        padding-left 20px
        font-size 26px