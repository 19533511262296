*::-moz-selection
    color #fff
    background alpha($cor-primaria, .8)

*::selection
    color #fff
    background alpha($cor-primaria, .8)

a
    text-decoration none

html
    scroll-behavior smooth !important
body
    background $cinza-200
