@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-Thin.ttf') format('ttf');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-ThinItalic.ttf') format('ttf');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-ExtraLight.ttf') format('ttf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-ExtraLightItalic.ttf') format('ttf');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-LightItalic.ttf') format('ttf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-Italic.ttf') format('ttf');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-MediumItalic.ttf') format('ttf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-SemiBoldItalic.ttf') format('ttf');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-BoldItalic.ttf') format('ttf');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-ExtraBold.ttf') format('ttf');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-ExtraBoldItalic.ttf') format('ttf');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-Black.ttf') format('ttf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-BlackItalic.ttf') format('ttf');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}