.novidade-page>.corretor__header
    margin-bottom 6rem
.novidade-page>.novidade
    display flex
    flex-direction column
    align-items center
    justify-content center
    gap 2rem
    width 50%
    max-width 1602px
    margin 0 auto
    padding 0 1rem

.novidade__header
    display flex
    flex-wrap wrap
    align-items center
    justify-content space-between
    gap 2rem
    border-bottom 1px solid $cinza-400
    padding 1rem 0
    width 100%
    &>h2
        font-weight 800
        font-size 2.5rem
        color $cor-secundaria
        flex 0 1 100%
    & p
        font-size 0.875rem
        color $cinza-400
        margin 0

.novidade__compartilhamento
    display inline-flex
    align-items baseline
    gap 0.5rem
    &-icons a, &-icons button
        background $cor-primaria
        &:hover
            background $laranja-escuro

.novidade__artigo
    width 100%
    &-img
        width 100%
        margin-bottom 3rem
        &>img
            border-radius 16px
            width 100%
            object-fit contain
    >p
        color $cinza-500
        font-size 1.35rem
        font-weight 400
        line-height 2.2rem
        &>img
            width 100%
            object-fit contain

.novidade__artigo-footer
    display inline-flex
    align-items center
    justify-content space-between
    flex-wrap wrap
    gap 1rem
    width 100%
    color $cinza-400
    border-top 1px solid $cinza-400
    padding 1rem 0

.novidade-page__veja-mais
    background $branco
    border-radius 6.25rem 6.25rem 0rem 0rem
    width 100%
    padding 4rem 0
    margin-top 6rem
    &>.container>h2
        color $cor-secundaria
        font-size 2.5rem
        font-weight 800
        margin-bottom 4rem
    & .novidades
        margin 4rem 0
        &__item:last-child
            border-bottom none
@media $tablet-large
    .novidade-page>.novidade
        width 84%
    .novidade__artigo >p
        font-size 1.4rem
        line-height 2.2rem

@media $tablet-medium
    .novidade-page__veja-mais
        border-radius 4.25rem 4.25rem 0rem 0rem

@media $mobile-large
    .novidade__artigo
        >p
            font-size 1rem
            line-height 1.5rem
    .novidade-page__veja-mais
        border-radius 3.25rem 3.25rem 0rem 0rem
    .novidade__header
        &>h2
            font-size 2rem
