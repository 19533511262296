.partners
    padding 2em 0 10em 0
    background $cor-terciaria

.partners__container
    position relative
    display flex
    align-items center
    padding 80px 40px 20px
    border-radius 10px
    background-color $branco
    box-shadow 0 14px 40px rgba(0, 0, 0, 0.15)

.partners__title
    position absolute 
    top 40px
    left 0
    padding-left 40px
    color $cor-terciaria
    font-weight 400
    text-transform none

    strong
        font-weight 700

    &:before
        content ''
        position absolute
        top 0
        left 0
        width 18px
        height 32px
        transform translateY(-5px)
        background-color $cor-primaria

.partners__list
    display flex
    flex-wrap wrap
    margin-top 2em

.partners__item
    display flex
    align-items center
    justify-content center
    margin 0 20px 20px
