.hero
    height 100%
    width 100%
    padding-top 2rem
    background linear-gradient(to bottom, $cor-secundaria 75%, transparent 25%);
    display flex
    align-items center
    justify-content center
    flex-direction column
    margin-bottom 2rem
    position relative

.hero__slide
    width 90%
    overflow hidden
    border-radius 18.75rem
    margin 0 auto
    &>img
        display: block
        width: 100%
        height: 100%;
        object-fit: contain;
.hero__navigation.swiper-pagination-bullets.swiper-pagination-horizontal
    bottom -2rem
    opacity 1
    transition 400ms all 
    &>.swiper-pagination-bullet
        width 25px
        height 10px
        background $cinza-400
        border-radius 70px
        vertical-align middle
        &-active
            height 15px
            background $cor-primaria