.input
    position relative
    display flex
    flex-direction column
    border none
    margin-bottom 20px

.input--select
    position relative

    &:before
        content ''
        position absolute
        right 0
        top auto
        bottom 0
        width 40px
        height 45px
        bg('arrow-down2.svg', center, center)
        background-size 12px auto
        pointer-events none

.input__error
    position absolute
    right 10px
    bottom -20px
    font-size 12px
    color $cor-erro
    font-weight 600
    text-align right

    &--radio-group
        position absolute
        left 0px
        bottom -15px
        font-size 12px
        color $cor-erro
        font-weight 600
        text-align right

    &--images
        position relative
        font-size 12px
        color $cor-erro
        font-weight 600
        margin-left: 35px
        text-align right

    &--checkbox
        position relative
        font-size 12px
        color $cor-erro
        font-weight 600
        text-align right

    &--comentario
        position: absolute
        bottom: -20px
        font-size 12px
        color $cor-erro
        font-weight 600

.has-error
    input, select 
        border-color $cor-erro !important 
        transition all 0.4s linear

.input__label
    margin-bottom 5px
    font-size 14px
    font-weight 400
    letter-spacing 0
    color $cinza-600

.input__field
    width 100%
    border 1px solid $cinza-300
    border-radius 8px
    min-height 45px
    padding 5px 20px
    font-family $font-primaria
    font-size 16px
    background $branco
    color $preto

.input__field:focus
    outline none

.input--checkbox
    .input__label
        display inline-block
        position relative
        cursor pointer
        font-size 14px
        font-weight 400
        letter-spacing normal
        text-transform none
        line-height 1.6
        min-height 40px
        padding-top 2px
        padding-left 38px
        margin-top 30px

        &:before,
        &:after
            transition all 200ms ease

        &:before
            content ''
            display block
            size 24px 24px
            position absolute
            top 0
            left 0
            border 2px solid $cinza-400
            border-radius 5px

        &:after
            content ''
            display inline-block
            position absolute
            top 4px
            left 9px
            transform rotate(70deg)
            opacity 0
            height 12px
            width 4.5px
            border-bottom 4px solid $branco
            border-right 4px solid $branco

    .input__field
        display none

        &:checked ~ .input__label
            &:before
                background $cor-primaria

            &:after
                opacity 1
                transform rotate(45deg)

    &.has-error
        .input__error
            left 0
            right auto
            top 50px

        label.input__label
            &:before
                border-color $cor-erro

.input--textarea
    display flex
    flex-direction column

    .input__label
        display flex
        align-items center
        width 90%
        height 70px
        padding 10px 0
        border-right none
        text-align left
        border-bottom 1px solid $cinza-400

.input__field--textarea
    height 100%
    padding 20px
    resize vertical

.input--radio
    .input__label
        display inline-block
        position relative
        cursor pointer
        font-size 14px
        font-weight 400
        letter-spacing normal
        text-transform none
        line-height 1.6
        min-height 40px
        padding-left 21px

        &:before,
        &:after
            transition all 200ms ease

        &:before
            content ''
            display block
            size 15px 15px
            position absolute
            top 3px
            left 0
            border 2px solid $cinza-400
            border-radius 100%
            box-sizing border-box

        &:after
            content ''
            display inline-block
            position absolute
            top 6px
            left 3px
            background $azul
            opacity 0
            size 9px 9px
            border-radius 100%

    .input__field
        display none

        &:checked ~ .input__label
            &:after
                opacity 1

    &.has-error
        .input__error
            left 0
            right auto
            top 50px

select.input__field
    height 100%
    -webkit-appearance none
    -moz-appearance none
    appearance none
    line-height normal

.input-text
    transition all 0.3s linear
    position relative
    display flex
    align-items flex-start
    flex-direction column
    gap 0.5rem
    font-weight 600
    font-size 14px
    padding-left 0.5rem
    width 100%

    &__label
        padding-left: 0.5rem
    
    &__input
        border none
        margin-left -0.5rem
        padding 1rem
        border-radius 50px
        border 1px solid transparent
        box-shadow 0px 3px 4px 0px rgba(0, 0, 0, 0.05)
        font-weight 600
        font-size 1rem
        font-family $font-primaria
        width 100%
        &::placeholder 
            color $cinza-300
            opacity 1
        &::-ms-input-placeholder
            color $cinza-300
        &:focus, &:focus-visible
            outline none
            border 1px solid $cor-secundaria
    
    &__textarea
        outline none
        resize none
        overflow auto
        border none
        margin-left -0.5rem
        padding 1rem
        border-radius 10px
        border 1px solid transparent
        box-shadow 0px 3px 4px 0px rgba(0, 0, 0, 0.05)
        font-weight 600
        font-size 1rem
        font-family $font-primaria
        width 100%
        &::placeholder 
            color $cinza-300
            opacity 1
        &::-ms-input-placeholder
            color $cinza-300
        &:focus, &:focus-visible
            outline none
            border 1px solid $cor-secundaria

.input-select
    display flex
    align-items center
    justify-content flex-start
    flex-direction column
    gap 0.5rem
    position relative
    width 100%
    &:after
        content ''
        position absolute
        right 1rem
        bottom 0.8rem
        width 15px
        height 15px
        replace('arrow-down-orange.svg')
        margin-left auto
        transition all 0.3s linear
    &--changed
        &:after
            transform rotateX(180deg)
            bottom 1.2rem

.input-select 
    &> select
        transition all 0.3s linear
        border-radius 32px
        box-shadow 0px 3px 4px 0px rgba(0, 0, 0, 0.05)
        cursor pointer
        height 50px
        min-width 230px
        width 100%
        padding 0.5rem 1rem
        padding-right 2rem
        background $branco
        color $preto
        font-family $font-primaria
        font-weight 600
        text-overflow ellipsis
        white-space nowrap
        appearance: none;
        /* safari */
        -webkit-appearance: none;
        border 1px solid transparent
        &:focus
            outline none
            border 1px solid $cor-secundaria
        &>option
            color $preto
        &:invalid,&>option[value=""]
            color $cinza-400


@media $tablet-medium
    .input.has-error .input__error
        top 3px

@media $tablet-large
    .input-text__input, .input-select>select
        height 42px

@media $mobile-large
    .input__error
        &--images
            margin-left: 0px
