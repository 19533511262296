.news
    padding 0 0 8em 0
    background $cor-secundaria

.news__header
    margin-bottom 3em

.news__title
    display block
    margin-bottom 2em
    text-transform uppercase
    font-size 9px
    color $cinza-600
    letter-spacing 2px
    font-weight 400

.news__intro
    font-size 46px
    font-weight 400
    color $branco

    strong
        font-weight 700

.news__list
    display grid
    grid-template-columns 1.5fr 1fr
    gap 30px
    justify-content flex-start

.news__link
    position relative
    display grid
    grid-template-columns 1fr 1fr
    max-width 720px
    width 100%
    color $verde
    border-radius 6px
    overflow hidden

.home .news__link
    max-width 640px

.news__link:hover .news__img
    transform scale(1.1)

.news__item
    .news__content:hover
        .news__subtitle
            color $cor-primaria
            text-decoration underline
    .news__thumb:hover
        &+.news__main
            .news__subtitle
                color $cor-primaria
                text-decoration underline

.news__subtitle
    margin 1em 0 2em
    font-size 16px
    font-weight 400
    line-height 1.5
    color $verde

.news__thumb
    display flex
    align-items center
    justify-content center
    width 100%
    height 100%
    overflow hidden

.news__img
    display block
    width 100%
    height 100%
    object-fit cover
    transition all 500ms ease

.news__main
    display flex
    flex-direction column
    justify-content space-between
    padding 25px 0
    background $cinza-100

.news__tags
    padding 0 35px

.news__content
    display flex
    flex-direction column
    justify-content space-between
    padding 0 35px
    flex-grow 1

.home .news__main
    background $branco

.news__date
    margin-top auto
    font-size 10px
    color $cinza-600

.news__item
    display flex

@media $notmobile
    .home
        .news__item:first-child
            position relative
            grid-row 1/4

            .news__tags
                .tag
                    color $branco

            .news__link
                display flex
                grid-template-columns auto
                max-width 920px
                color $branco

            .news__thumb:after
                content ''
                position absolute
                z-index 2
                square 100%
                background-image linear-gradient(0deg, #000000 10.67%, rgba(0, 0, 0, 0) 63.24%)
                opacity .8
                transition all 200ms ease

            .news__main
                position absolute
                z-index 2
                bottom 0
                left 0
                width 100%
                padding 20px 50px 40px
                background transparent

            .news__link:hover
                .news__subtitle
                    color $cor-primaria
                    text-decoration none

                .news__thumb:after
                    opacity 1

            .news__subtitle
                max-width 300px
                font-size 20px
                margin-bottom 0
                color $branco

            .news__date
                position absolute
                bottom 40px
                right 50px
                color $branco
                z-index 2

.news__action
    align-self flex-end
    color $verdeescuro

    &:hover
        color $branco
        background-color $cor-primaria
        border-color $cor-primaria

@media $desktop-small
    .news__link
        grid-template-columns .75fr 1fr

    .news__main
        padding 20px

@media $tablet-large
    .news__list
        grid-template-columns 1fr

    .home .news__link
        max-width 860px

@media $tablet-small
    .news__intro
        font-size 32px

    .news__title
        font-size 11px

    .news__action
        width 100%

    .news__link
        grid-template-columns 1fr

    .news__tags
        padding 0 5px

    .news__content
        padding 0 5px
