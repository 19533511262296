.encontre-imovel
    margin 8rem auto
    display grid
    grid-template-columns 1fr
    place-items center
    gap 1rem

    &__header
        text-align center
        font-size 3.75rem
        font-weight 400
        color $cor-secundaria

    &__form
        display flex
        align-items center
        justify-content space-between
        gap 2rem
        border 1px solid $branco
        border-radius 10px
        width fit-content

    &__form-inputs
        display grid
        grid-template-columns 1fr 1fr
        grid-template-rows 1fr 1fr
        place-items start
        gap 6rem
        row-gap 1rem
        padding 1rem 2rem

    &__input
        display flex
        align-items center
        justify-content center
        gap 0.5rem
        width 100%
        &-titulo
            color $cinza-500
            font-size 14px
            line-height 1.2rem
            margin-right auto

.encontre-imovel__input-text
    padding 0.2rem 0.5rem
    border-radius 50px
    border 1px solid $cinza-400
    box-shadow 0px 3px 4px 0px rgba(0, 0, 0, 0.05)
    font-weight 500
    font-size 0.875rem
    width 100px
    &::placeholder
        color $cinza-400
        opacity 1
    &::-ms-input-placeholder
        color $cinza-400
    &:focus, &:focus-visible
        outline none
        border 1px solid $cor-secundaria

.encontre-imovel__input-checkbox
    display inline-flex
    color $cinza-500
    font-size 0.875rem
    font-weight 500
    align-items center
    user-select: none;

    &>span
        position relative
        display flex
        align-items center

    &>input[type="radio"]
        position absolute
        clip rect(1px, 1px, 1px, 1px)
        padding 0
        border 0
        height 1px
        width 1px
        overflow hidden

        &:checked + span::after
            background-color $cor-secundaria

        &:checked + span
            color  $cor-secundaria

        &:focus + span::before
            border-color  $cor-secundaria

        &:disabled
            opacity .85
            & + span
                color $cinza-400

    &>span::before
        content ""
        display inline-block
        border-radius 3px
        background-color $branco
        margin-right 8px
        margin-bottom 6px
        height 14px
        width 14px
        border 1px solid $cinza-400

    &>span::after
        content ""
        display inline-block
        height 10px
        width 10px
        border-radius 3px
        background-color transparent
        left 3px
        top 3px
        position absolute

.encontre-imovel__list
    display grid
    grid-template-columns: repeat(3, 1fr)
    gap 4rem
    margin-top 4rem
    place-items center start

.encontre-imovel__card
    width 20rem
    min-height 34rem
    display flex
    flex-direction column
    justify-content space-between
    background $branco
    gap 1rem
    box-shadow 0px 4px 40px 0px rgba(0, 0, 0, 0.15)
    border-radius 10px
    padding 0.5rem

    &-info
        padding 0 1rem
        display flex
        flex-direction column
        gap 1rem
    &-titulo
        color $cor-secundaria
        line-height 1.25rem
        text-transform uppercase
        font-weight 600
    &-localizacao
        color $cinza-500
        font-size 0.875rem
        line-height 1.25rem
    &-detalhes
        display flex
        align-items center
        justify-content flex-start
        gap 1rem
    &-footer
        width 100%
        border-top 1px solid $cinza-300
        display flex
        align-items center
        justify-content center
        padding-top 0.5rem
    &-status, &-programa
        margin 0
        border-radius 2.5rem
        padding 0.5rem 1rem
        font-size 0.875rem
        font-weight 600
        color $branco
    &-status
        background $cor-primaria
    &-programa
        background $cor-secundaria

    &-propriedades
        display grid
        grid-template-columns 1.2fr 1fr
        justify-content space-between
        margin-top 1rem
        gap 1rem
        font-size 0.8rem

.encontre-imovel__card
    &-button
        display none
        position absolute
        opacity 0.8
        top 50%
        padding 0
        height 40px
        width 40px
        background $branco
        &:hover
            opacity 1
        &:after
            display none
        &--left
            left 0.5rem
        &--right
            right 0.5rem
    &-carrosel
        width 100%
        height 100%
    &-container
        overflow hidden
        border-radius 8px
        width 100%
        min-height 230px
        height 230px
        position relative
    &-image
        width 100%
        height 100%
        object-fit cover
    .swiper-pagination-bullet
        background alpha($branco, 0.9)
        border-radius 70px
        height 5px
        width 18px
    .swiper-pagination-bullet-active
        position relative
        top 2px
        background $cor-primaria
        height 9px
    &:hover &-button
        display block


.encontre-imovel__card-propriedade
    position relative
    display inline-flex
    align-items center
    gap 0.5rem
    color $cinza-500

    &--area>i
        replace('icon-area.svg')
    &--quartos>i
        replace('icon-quartos.svg')
    &--vagas>i
        replace('icon-vagas.svg')
    &--suites>i
        replace('icon-suites.svg')
     &--varanda>i
        replace('icon-varanda.svg')
    &--elevador>i
        replace('icon-elevador.svg')
    &--giardino>i
         replace('icon-giardino.svg')
    &--area>i, &--quartos>i, &--vagas>i, &--suites>i, &--varanda>i, &--elevador>i, &--giardino>i
        position relative
        width 20px
        height 20px
        top 2px
        display inline-block

@media $desktop-small
    .encontre-imovel__list
        gap 2rem
    .encontre-imovel__card-footer
        max-height 38px

@media $tablet-medium
    .encontre-imovel
        margin: 4rem auto
    .encontre-imovel__header
        font-size 2.5rem
    .encontre-imovel__form, .encontre-imovel__form-inputs
        flex-direction column
    .encontre-imovel__form-inputs
        grid-template-columns 1fr
        gap 1rem
        & :nth-child(2)
            order 1
    .encontre-imovel__form > button
            width 200px
    .encontre-imovel__input
        width 100%
        & :first-child
            margin-right auto
    .encontre-imovel__form
        gap 1rem
        padding 0.5rem

    .encontre-imovel__card-button
        display block
    .encontre-imovel__list
        grid-template-columns 1fr 1fr
    .encontre-imovel__card
        width 20rem
    .encontre-imovel__card-propriedades
        font-size 0.75rem

@media $tablet-small
    .encontre-imovel__list
        gap 1rem
        grid-template-columns 1fr

@media $mobile-large
    .encontre-imovel__card
        width 20rem
        &-detalhes
            align-items flex-start
            flex-direction column

    .encontre-imovel__input
        flex-wrap wrap
        & :first-child
            flex 0 1 100%
