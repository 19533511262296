.video
    position relative
    max-width 642px

.video__thumb
    border-radius 8px
    overflow hidden

.video__img
    display block
    width 100%

.video__play
    position absolute
    top calc(50% - 25px)
    left calc(50% - 25px)
    square 50px
    display block

    &:before
        content ''
        position absolute
        display block
        square 50px
        center()
        border-radius 50px
        background $branco
        transition-duration .3s
    
    &:after
        content ''
        position absolute 
        size 20px 30px
        center(5px, 5px)
        replace('ico-play.svg')
        transition-duration .3s

    em
        position absolute
        display inline-block
        left 240px
        top 4px
        color $branco
        padding 13px 16px 10px 22px
        font-style normal
        font-size 12px
        letter-spacing 6px
        text-transform uppercase
        background $verde  
        transition-duration .3s

        &:before
            content ''
            position absolute
            top 50%
            right 140px
            width 200px
            height 1px
            background alpha($branco, 50%)

.video__link
    &:hover
        em
            color $verde
            background $branco
            transition-duration .3s

        .video__play
            &:before
                content ''
                position absolute
                display block
                square 55px
                center()
                border-radius 55px
                transition-duration .3s
            
            &:after
                content ''
                position absolute 
                size 20px 30px
                center(5px, 5px)
                replace('ico-play.svg')
                transition-duration .3s

