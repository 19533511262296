.imovel-page__header
    height 100%
    min-height 500px
    width 100%
    padding-top 2rem
    background linear-gradient(to bottom, $cor-secundaria 60%, transparent 40%);
    display flex
    align-items center
    justify-content center
    flex-direction column
    margin-bottom 6rem
    position relative
    &-img
        width 90%
        max-height 500px
        overflow hidden
        border-radius 0 6.25rem
        margin 0 auto
        position relative
        &>img
            display: block
            width 100%
            height 100%
            object-fit cover
            max-height 500px
        .img__filtro
            position absolute
            top 0
            left 0
            width 100%
            height 100%
            background $azul  
            opacity: 60%
            z-index 0
    &-container
        position absolute
        padding-top 3rem
        top 2rem
        left 8%

    &-breadcrumb
        width fit-content
        background $branco
        padding 0.5rem
        margin-top 0
        margin-bottom 4rem
        & :first-child
            color $cor-primaria
        & >p
            color $cor-secundaria
            margin 0
    &-titulo
        font-size 3.125rem
        font-weight 400
        margin-bottom 4px
        width 75%
        text-transform uppercase
        line-height 1.5
        &>span
            padding 0.5rem
            color $branco
            display inline
            -webkit-box-decoration-break clone
            -ms-box-decoration-break clone
            -o-box-decoration-break clone
            box-decoration-break clone
    &-localizacao
        font-weight 400
        width fit-content
        padding 0.5rem
        font-size 1.25rem
        margin-bottom 4rem
        color  $branco

.imovel
    display flex
    flex-direction column
    gap 2rem
    &__titulo
        font-size: 2.5rem
        color $cor-secundaria
        font-weight 400
        &>b
            font-weight 800
    &__detalhes
        display flex
        flex-direction column
        gap 1rem
        width 45%
    &__descricao
        color $cinza-500
        line-height 1.5rem

.imovel__informacoes
    background $branco
    padding 2rem 4rem 4rem
    border-radius 10px
    margin-bottom 2rem

    &-subtitulo
        letter-spacing 4px
        font-size 0.875rem
        font-weight 400
        text-transform uppercase
        color $cinza-500
        margin-bottom 2rem
.imovel__lista
    display flex
    align-items flex-start
    justify-content space-between
    flex-wrap wrap
    gap 3rem
.imovel__sublista
    display inline-flex
    flex-direction column
    gap 1rem
    list-style inside
    font-weight 500
    font-size 0.875rem
.imovel__item>.encontre-imovel__card-propriedades 
    grid-template-columns 1fr

.imovel__item, .imovel__info
    display flex
    flex-direction column
    gap 3rem
    max-width 30vw
.imovel__info
    gap 1rem
    max-width 20vw
    &-detalhe
        color $cinza-500
        font-size 0.875rem
        line-height 1.25rem
.imovel__info-titulo
    color $cor-secundaria
    font-weight 500
    font-size 1rem
    position relative
    display inline-flex
    align-items center
    gap 0.5rem
    &--terreno>i
        replace('icon-terreno.svg')
    &--localizacao>i
        replace('icon-pin-orange.svg')
    &--area>i
        replace('icon-area.svg')
    &--area>i, &--terreno>i, &--localizacao>i
        position relative
        width 16px
        height 16px
        bottom 2px
        display inline-block

.imovel__no-data
    font-style italic
.imovel__no-image
    font-style italic
    text-align center

.imovel__galeria, .imovel__plantas
    position relative
    margin-bottom 4rem
    display flex
    flex-direction column
    gap 2rem
    &-container
        width 90%
        height 100%
        margin-left auto
        margin-right auto
        margin-bottom 1rem
        position relative
        & img
            width 100%
    &>button
        top 55%
        display block
        opacity 1
        box-shadow 0px 3px 4px 0px rgba(0, 0, 0, 0.05)
    .swiper-button-prev
        left -0.5rem
    .swiper-button-next
        right -0.5rem
    .swiper-pagination-bullet
        background alpha($preto, 0.9)
        border-radius 70px
        height 5px
        width 18px
    .swiper-pagination-bullet-active
        transition height 0.2s linear
        position relative
        top 2px
        background $cor-primaria
        height 9px
        
    .imovel__planta
        &>span
            color $cinza-500
            font-size 0.875rem
            font-weight 600
        & img
            width 100%
            margin-bottom 1rem
    .swiper-pagination
        bottom 0 !important

.imovel__localizacao
    display flex
    flex-direction column
    gap 2rem
    margin-bottom 2rem
    .imovel__mapa
        width 100%
        height 45vh
        max-height 432px
        display flex
        align-items center
        justify-content center
        background $branco
        // >img
        //     width 100%
        >span
            text-align center
            width 100%
            font-size 1.1rem
            font-style italic
    &-footer
        width 100%
        display inline-flex
        align-items center
        justify-content space-between
        flex-wrap wrap
        gap 2rem

@media $desktop-large
    .imovel-page
        &__header
            min-height 460px
        &-titulo
            font-size 2rem
        &-localizacao, &-breadcrumb
            margin-bottom 2rem

@media $desktop-medium
    .imovel-page__header
        min-height 410px
        &-titulo
            margin-bottom 14px
            font-size 1.5rem
            &>span
                padding 0.5rem
        &-localizacao
            margin-bottom 2rem
            

@media $tablet-large
    .imovel__item, .imovel__info
        max-width 30vw

@media $tablet-medium
    .imovel-page__header
        margin-bottom 2rem
        &-container
            padding-top 1rem
        &-img
            border-radius 0 1.875rem
            height 310px
    .imovel__detalhes
        width 100%
    .imovel__galeria, .imovel__plantas
        overflow hidden
        &-container
            width 100%
        &>button
            display none

@media $mobile-large
    .imovel__informacoes
        padding 2rem 2rem 4rem
    .imovel-page__header
        &-titulo
            font-size 1.125rem
        &-localizacao
            font-size 0.875rem
            margin-bottom 4rem
        &-breadcrumb
            margin-bottom 1rem
    .imovel__item, .imovel__info
        max-width 100%

@media $mobile-small
    .encontre-imovel__card
        width 18rem
    .imovel__titulo
        font-size 2rem