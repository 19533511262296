.btn
    position relative
    display inline-flex
    align-items center
    justify-content center
    height 40px
    min-width 42px
    padding 1rem 1.5rem
    gap 1rem
    border 2px solid transparent
    box-sizing border-box
    font-family $font-primaria
    font-size 16px
    font-weight 600
    line-height 1
    letter-spacing 0
    text-align center
    text-transform none
    cursor pointer
    transition all 150ms ease

    &--size
        &-default
            width fit-content
        &-large
            width 200px

    &--radius
        &-full
            border-radius 50px
        &-small
            border-radius 6px
        &-none
            border-radius 0

    &--default
        background $cor-primaria
        color #fff
        &:hover,
        &:focus
            color $branco
            background $cor-secundaria

    &--hide-text
        color: transparent !important
        pointer-events: none
        cursor none

    &--secondary
        background $cor-primaria
        color #fff
        &:hover,
        &:focus
            color $cor-secundaria
            background $branco

    &--terciary
        background $azul-escuro1
        color #fff
        &:hover,
        &:focus
            background $cor-terciaria

    &--outline-light
        color $branco
        border 2px solid $cor-primaria
        box-shadow none
        background transparent
        &:hover,
        &:focus
            border 2px solid $branco
            color $cor-secundaria
            background $branco
    &--outline
        color $cor-secundaria
        border 2px solid $cor-primaria
        box-shadow none
        background transparent
        &:hover,
        &:focus
            border 2px solid $cor-secundaria
            color $branco
            background $cor-secundaria
    
    &--text
        display inline-flex
        color $cor-primaria
        text-decoration none
        font-size 14px
        line-height 1.2rem
        border-radius 0
        height fit-content
        padding 0
        background transparent
        &:hover,
        &:focus
            color $cor-secundaria
    &--icon
        padding 0.8rem
        background $cor-primaria
        &:hover,
        &:focus
            background $cor-secundaria

    &--icon-position-left
        &>i
            order -1
            margin-left 0
            margin-right 0

    
    &--icon-send
        position relative
        height 20px
        width 24px
        bottom 4px
        left 2px
        replace('arrow-send.svg')
        display inline-block
        transform: rotate(-45deg)

    &--icon-redirect
        position relative
        square 16px
        replace('icon-link-blue.svg')
        display inline-block
        margin-left auto
        scale 1.4
    &--outline-light>.btn--icon-redirect
        filter brightness(0) invert(100%)
    &--outline-light:hover>.btn--icon-redirect, &--outline-light:focus>.btn--icon-redirect
        filter brightness(0) invert(17%) sepia(98%) saturate(739%) hue-rotate(177deg) contrast(96%)
    &--outline:hover>.btn--icon-redirect
        filter brightness(0) invert(100%)

    &--icon-mais
        position relative
        square 16px
        replace('icon-mais.svg')
        margin-left auto
        display inline-block
    
    &--icon-left
        position relative
        square 24px
        replace('arrow-down-orange.svg')
        display inline-block
        transform: rotate(90deg)
        inset 15%
        translate -50% -20%
        margin-left auto

    &--icon-right
        position relative
        square 24px
        replace('arrow-down-orange.svg')
        display inline-block
        transform: rotate(-90deg)
        inset 15%
        translate 5% -20%
        margin-left auto

    &--icon-comment
        position relative
        square 24px
        replace('icon-fale-conosco.svg')
        margin-left auto
        display inline-block
    &--terciary:hover>.btn--icon-comment
        filter brightness(0) invert(100%)
    
    &--icon-whatsapp
        position relative
        square 24px
        replace('icon-whatsapp.svg')
        margin-left auto
        display inline-block
    &--icon-data
        position relative
        square 24px
        replace('arrow-up.svg')
        margin-left auto
        display inline-block
    &--icon-download
        position relative
        square 24px
        replace('icon-import-orange.svg')
        margin-left auto
        display inline-block
    &--outline:hover>.btn--icon-download
        replace('icon-import-white.svg')

    &:disabled,
    &.button--disabled
        opacity 0.35
        background $cinza-300
        border-color $cinza-300
        pointer-events none

        &:before,
        &:after
            background $cinza-300
  //&:active
    // Adicione estilos de clique aqui

// .button--loading
//     position relative
//     cursor default
//     pointer-events none
//     opacity .5
//     background-color $cinza-600

//     &:after
//         content ''
//         position absolute
//         top calc( 50% - 10px)
//         right 20px
//         display block
//         width 12px
//         height 12px
//         border-radius 12px
//         margin-left 5px
//         background-color transparent
//         border 3px solid $branco
//         border-top-color transparent
//         animation spin 600ms linear infinite
@keyframes spin
    to
        transform rotate(360deg)

@media $tablet-large
    .btn
        height 42px
        font-size 14px
    .btn--icon
        padding 0
    .btn--icon-redirect
        replace('icon-link.svg')

// @media $mobile-small
//     .button
//         padding-right 30px
//         padding-left 30px
