.numeros
    display grid
    grid-template-columns 1fr 2fr
    padding 2em 0

.quemsomos__numeros
    margin-top 2em
    margin-bottom 2em
    grid-template-columns 1fr 920px

.numeros__list
    display grid
    grid-template-columns 1fr 1fr

.quemsomos__numeros 
    .numeros__list
        grid-template-columns auto 420px

.numeros__title
    position relative
    display flex
    flex-direction column
    max-width 470px
    width 100%
    padding-top 20px
    padding-left 50px
    margin-top 2em
    border-top 2px solid alpha($branco, 10%)
    line-height .9
    font-size 32px
    font-weight 700
    text-transform lowercase

    span
        position relative
        display flex
        font-weight 400

        &:after
            content ''
            position absolute
            bottom -10px
            left 0
            width 50px
            height 2px
            background alpha($branco, 25%)

    &:before
        content ''
        position absolute
        top 20px
        left 0
        size 45px 49px
        replace('ico-sharp.svg')

.numeros__subtitle
    display flex
    margin-bottom .5em
    font-size 26px
    font-weight 700

    strong
        width 100px
        margin-right 10px
        font-size 50px
        line-height 1.3

.numeros__item
    position relative
    padding-top 90px
    
    &:before
        content ''
        position absolute
        top 0
        left 0
        size 110px 100px

    &--money:before
        replace('ico-money.png')

    &--watts:before
        replace('ico-energia.png')

    p
        display block
        max-width 330px
        padding-left 100px
        line-height 1.8

.sobre__numeros
    display block
    bg('numeros-onda.svg', center, bottom)
    background-size 100% auto

    .numeros__subtitle
        color $cor-terciaria

    .numeros__title
        border none
        padding-top 0
        padding-left 75px
        margin-top 0
        color $cor-terciaria
        font-size 54px

        &:before
            top 10px
            size 60px 70px
            background-size contain

        span:after
            display none

    .numeros__container
        position relative
        display grid
        grid-template-columns 1.5fr 2fr
        padding 3em 4em 3em 5em
        align-items center
        border-radius 10px
        background-color $cinza-100

        &:before
            content ''
            position absolute
            bottom 0
            left 0
            size 420px 100%
            bg('numeros-bg.svg', 0)
            background-size contain

        &:after
            content ''
            position absolute
            top -20px
            left 13%
            size 46px 78px
            bg('numeros-gota.svg', 0)
            background-size contain

@media $desktop-large
    .quemsomos__numeros
        grid-template-columns 1fr 1fr 1fr
        gap 0
        
        .numeros__list
            grid-column 2/4
            grid-template-columns 1fr 1fr
            gap 3em
            
    .quemsomos__numeros
        margin 5em 0

        .numeros__title
            max-width 90%

@media $desktop-small
    .numeros__subtitle
        font-size 22px
    
    .sobre__numeros .numeros__title
        font-size 44px

    .sobre__numeros .numeros__container
        padding 2em 3em

    .numeros__list
        gap 2em
    
    .numeros__subtitle strong
        line-height 1.1
        margin-right -15px
    
@media $tablet-large
    .quemsomos__numeros
        grid-template-columns 1fr    

    .quemsomos__numeros .numeros__list
        grid-row auto
        grid-column auto
    
    .quemsomos__numeros .numeros__title
        margin-top 0
        border-top 0
        margin-bottom 1.5em

    .numeros__title span:after
        width 50%
        left -50px

    .sobre__numeros .numeros__container
        grid-template-columns 1fr 2fr

    .sobre__numeros .numeros__title
        padding-left 55px
        font-size 30px
    
    .sobre__numeros .numeros__title::before
        top 0
        size 40px 50px
    
    .numeros__subtitle
        font-size 16px

    .numeros__item
        padding-top 70px
        
    .numeros__item p
        max-width 220px
        line-height 1.5
        padding-left unset

    .numeros__item::before
        size 90px 60px
        background-size contain

    .numeros__subtitle strong
        font-size 44px

@media $tablet-medium
    .sobre__numeros .numeros__container,
    .numeros__list
        display flex
        flex-direction column

    .sobre__numeros .numeros__container
        padding 3em
        border-radius 10px
        overflow hidden

    .sobre__numeros .numeros__container::before
        size 100% 400px
        bottom auto
        top 0
        replace('numeros-onda2.svg')
        pointer-events none
        background-size 100% auto
        background-position center -150px

    .sobre__numeros .numeros__container::after
        display none
    
    .sobre__numeros .numeros__title
        margin-bottom 1em
        color $branco

@media $tablet-small
    .numeros__title span::after 
        width 100%

    .sobre__numeros .numeros__container::before
        background-position center -120px

@media $mobile-large
    .sobre__numeros .numeros__container::before
        background-position center -10vw
    
    .sobre__numeros
        background-position center calc(100% + 1px)

    .sobre__numeros .numeros__container
        padding 2em

@media $mobile-medium
    .numeros__subtitle
        font-size 22px

        strong
            font-size 40px
    
    .numeros__title
        font-size 28px
    
    .sobre__numeros .numeros__title
        font-size 26px

    .numeros__item
        padding-top 75px
    
    .sobre__numeros .numeros__subtitle
        font-size 18px