.subheader
    position relative
    display flex
    align-items center
    height 200px
    padding 3em 0
    background $cor-terciaria

    &:after
        content ''
        position absolute
        bottom -88px
        right 0
        width 30vw
        height 152px
        replace('subheader-ilu.svg')

.subheader__container
    display flex
    flex-direction column
    align-items flex-start
    
.subheader__action
    order 4
    margin-top 3em
    
.subheader__description
    order 3
    font-size 18px
    line-height 1.8
    margin-top 4em

.subheader__breadcrumb
    order 1

.subheader__title.title
    order 2
    text-align left
    font-size 50px
    color $branco

.theme-light
    .subheader
        height auto
        background transparent
        
        &:after
            display none

    .subheader__title.title
        color $cor-terciaria

@media $tablet-large
    .subheader__title.title
        font-size 40px
    
    .subheader
        padding 2em 0
        height auto

@media $tablet-medium
    .subheader__title.title
        font-size 36px
    
@media $tablet-small
    .subheader::after
        bottom -47px
        width 50vw
        height 80px
        background-size auto 80px