.portfolio__galeria, .portfolio__plantas
    position relative
    margin-bottom 4rem
    display flex
    flex-direction column
    gap 2rem

    &-container
        width 90%
        height 100%
        margin-left auto
        margin-right auto

    & > button
        top 55%
        display block
        opacity 1
        box-shadow 0px 3px 4px 0px rgba(0, 0, 0, 0.05)

    .swiper-button-prev
        left -0.5rem

    .swiper-button-next
        right -0.5rem

    &-pagination
        bottom -2rem !important

    .portfolio__planta
        & > span
            color $cinza-500
            font-size 0.875rem
            font-weight 600

        & > img
            width 100%
            margin-bottom 1rem

.portfolio__historia
    display grid
    grid-template-columns 1fr 1fr
    place-items start
    gap 4rem
    margin-bottom 4rem

    &>p
        text-align justify
        color $cinza-500
        line-height 1.5rem
        font-size 1rem
        width 85%

.portfolio__list
    display grid
    grid-template-columns 1fr 1fr
    place-items start
    gap 4rem

.portfolio__item
    height 95%
    width 100%
    border-radius 10px
    position relative
    overflow hidden

    .portfolio__image
        width 100%
        object-fit contain

    .portfolio__content
        position absolute
        bottom 2rem
        left 2rem
        display flex
        flex-direction column
        gap 1px

    & >a, >a:visited
        color $cor-secundaria
    & >a:hover
        .portfolio__title, .portfolio__date
            color $branco
            background-color $cor-secundaria
        .portfolio__date>span
            color $branco
.portfolio__title
    color $cor-secundaria
    font-size 1.25rem
    font-weight 600
    text-transform uppercase
    width fit-content
    padding 0.5rem 1rem
    background $branco
    transition all 0.4s ease-out

.portfolio__date
    padding 0.5rem 1rem
    background $branco
    transition all 0.4s ease-out

    &-span
        color $cinza-500
        font-weight 400
        font-size 0.875rem
        text-transform uppercase
        transition all 0.4s ease-out

.portfolio__date-span:last-child
    margin-left 0.5rem
    border-left 1px solid $cinza-600
    padding-left 0.5rem

@media $desktop-large
    .portfolio__historia > p
        width 100%

    .portfolio__list
        gap 2rem

@media $tablet-medium
    .portfolio__list
        grid-template-columns 1fr

@media $mobile-large
    .portfolio__historia
        grid-template-columns 1fr
        gap 1rem

    .portfolio__item .portfolio__content
        left 0.5rem
        bottom 1rem
        >.portfolio__date
            display flex
            flex-direction column
            width fit-content
            gap 0.5rem
            & :last-child
                margin-left 0
                border-left none
                padding-left 0


    .portfolio__title
        font-size 1rem

    .portfolio__date-span
        font-size 12px
