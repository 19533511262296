.breadcrumb
  display flex
  align-items center
  margin-bottom .75em

.breadcrumb__item
  display inline
  color $branco
  font-weight 300
  font-size 14px
  line-height 1.4
  text-transform uppercase

  &:after
    content '>'
    margin 0 5px

  &:last-child:after
    content ''

.breadcrumb__link
  color $branco

  &:hover
    color $cor-secundaria
    text-decoration underline

.theme-light 
  .breadcrumb__link,
  .breadcrumb__item
    color $cor-primaria
  
@media $tablet-medium
  .breadcrumb__item
    font-size 10px
  
  .breadcrumb
    margin-bottom 0.5em