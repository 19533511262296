.pagination
    display inline-flex
    align-items center
    justify-content center
    gap 4rem
    list-style none
    margin 2rem 0
    padding 0
    &__button
        top inherit
        padding 0
        height 40px
        width 40px
        background $branco
        box-shadow 0px 4px 4px 0px #00000026
        &>i
            margin 0
        &:after
            display none

    &__nav
        display inline-flex
        align-items center
        gap 1rem
    
    &__item
        height max-content
        background transparent
        color $cor-primaria
        padding 0.5rem
        border 1px solid $branco
        &--active
            background $branco
            color $cinza-500
            border 1px solid $branco
