.quemsomos
    position relative
    z-index 1
    min-height 100vh
    padding 100px 0
    color $branco
    background $cor-terciaria
    transition all 300ms ease

    &:before
        content ''
        position absolute
        z-index 1
        top -22%
        left 0
        size 630px 1790px
        pointer-events none
        replace('quemsomos-bg.svg')

.home--instalacao .quemsomos
    background-color $laranja

.home--assinatura .quemsomos
    background-color $turquesa

.home--locacao .quemsomos
    background-color $verde

.quemsomos__next
    position absolute
    z-index 3
    top -7%
    left 2%
    display flex
    align-items center
    flex-direction column
    square 60px
    font-size 10px
    text-transform uppercase
    color $cinza-300
    line-height 1
    letter-spacing 0
    font-weight 400
    text-transform uppercase

    &:before
        content ''
        display block
        width 50px
        height 30px
        margin-bottom 10px
        bg('arrow-down.svg', center, center)
        background-size contain
        transition transform 100ms ease

    &:hover
        text-decoration underline
        color $amarelo

        &:before
            transform translateY(5px)

.quemsomos__header
    position relative
    z-index 2
    display grid
    grid-template-columns 240px 440px 200px 240px 420px
    justify-content end
    margin-bottom 5em

    .quemsomos__description
        grid-area desc
        grid-column 5/6
        grid-row 1/3
        // margin 200px 0 0
        margin 14em 0 0

    .quemsomos__subtitle
        position relative
        z-index 2
        grid-area subtitle
        grid-column 3/5
        grid-row 1/2
        color $branco
        pointer-events none
        font-size 52px

.quemsomos__title
    grid-area title
    grid-column 1/2
    grid-row 1/2
    text-transform uppercase
    color $ciano
    line-height .9
    font-size 80px
    font-weight 400

    span
        display block
        square 320px
        transform rotate(-90deg)
        text-align right

    strong
        font-weight 700

.quemsomos__video
    position relative
    grid-area video
    grid-column 2/4
    grid-row 1/2
    margin-top 90px

.quemsomos__thumb
    border-radius 8px
    overflow hidden

.quemsomos__img
    display block
    width 100%
    height 100%
    object-fit cover

.quemsomos__play
    position absolute
    top calc(50% - 25px)
    left calc(50% - 25px)
    square 50px
    display block

    &:before
        content ''
        position absolute
        display block
        square 50px
        center()
        border-radius 50px
        background $branco
        transition-duration .3s
    
    &:after
        content ''
        position absolute 
        size 20px 30px
        center(5px, 5px)
        replace('ico-play.svg')
        transition-duration .3s

    em
        position absolute
        display inline-block
        left 240px
        top 4px
        color $branco
        padding 13px 16px 10px 22px
        font-style normal
        font-size 12px
        letter-spacing 6px
        text-transform uppercase
        background $verde  
        transition-duration .3s

        &:before
            content ''
            position absolute
            top 50%
            right 140px
            width 200px
            height 1px
            background alpha($branco, 50%)

.quemsomos__link
    &:hover
        em
            color $verde
            background $branco
            transition-duration .3s

        .quemsomos__play
            &:before
                content ''
                position absolute
                display block
                square 55px
                center()
                border-radius 55px
                transition-duration .3s
            
            &:after
                content ''
                position absolute 
                size 20px 30px
                center(5px, 5px)
                replace('ico-play.svg')
                transition-duration .3s

.quemsomos__description
    font-size 14px
    font-weight 400
    line-height 2

.quemsomos__action
    margin-top 2em

.quemsomos__grupo
    position relative

    > .container
        display grid
        grid-template-columns 1fr 500px 420px
        margin-top 2em
        margin-bottom 2em
    
    &:before
        content ''
        position absolute
        left 0
        top 5%
        z-index 2
        size 545px 358px
        pointer-events none
        replace('ilu-equatorial.png')

    .quemsomos__brand
        margin-bottom 2em
        margin-left -25px

    .quemsomos__subtitle
        margin-bottom 1em

    .quemsomos__subtitle,
    .quemsomos__description,
    .quemsomos__brand
        grid-column 2/4

    .quemsomos__subtitle
        font-size 32px
        max-width 300px
    
    .quemsomos__description
        display grid
        grid-template-columns auto 420px
        gap 80px

@media $desktop-large
    .quemsomos::before 
        top -15%
        width 30vw
        background-size contain
    
    .quemsomos
        padding-top 50px
    
    .quemsomos__header 
        // grid-template-columns 12.5vw 22vw auto 12.5vw 22vw
        grid-template-columns 12vw 25vw auto 12vw 25vw
        justify-content start

    .quemsomos__header .quemsomos__subtitle
        font-size 40px
        max-width 360px

    .quemsomos__header .quemsomos__description
        margin-top 8em
        
    .quemsomos__grupo:before
        width 28vw
        background-size contain

    .quemsomos__grupo > .container
        grid-template-columns 1fr 1.5fr 1fr

        .numeros__list
            grid-template-columns 1fr 1fr
            grid-column 2/4
            gap 3em

    .quemsomos__grupo .quemsomos__description
        grid-template-columns 1fr 1fr
        gap 3em
    
    .quemsomos__title
        font-size 60px
    
@media $desktop-small
    .quemsomos
        padding-bottom 0
    
@media $tablet-large
    .quemsomos::before
        top 10%
        width 25vw
    
    .quemsomos__next
        display none

    .quemsomos__header
        grid-template-columns 260px auto
        margin-top 8em

    .quemsomos__title
        font-size 40px

    .quemsomos__title
        grid-column 1/2
        grid-row 1/2

    .quemsomos__video,
    .quemsomos__header .quemsomos__description,
    .quemsomos__header .quemsomos__subtitle
        grid-column 2/3
        grid-row auto

    .quemsomos__header .quemsomos__subtitle
        max-width 450px
        font-size 32px

    .quemsomos__video
        margin-left -260px
        max-width 110%

    .quemsomos__video,
    .quemsomos__header .quemsomos__description
        margin-top 5em

    .quemsomos__header .quemsomos__description
        max-width 80%

    .quemsomos__title span
        width auto
        height auto
        transform none
        text-align left
    
    .quemsomos__grupo > .container
        grid-template-columns 1fr

    .quemsomos__grupo::before 
        top -15%
        width 33vw
        right 0
        left auto
        transform scaleX(-100%)

@media $tablet-small
    .quemsomos__header
        display flex
        flex-direction column
        margin-top 4em
        margin-bottom 2em
    
    .quemsomos::before 
        top 7%
        width 35vw
        
    .quemsomos__video
        margin-left 0
        max-width 100%
    
    .quemsomos__play em
        display none

    .quemsomos__title
        max-width 200px
        margin-bottom 1em
    
    .quemsomos__header .quemsomos__subtitle
        font-size 24px
        max-width 80%

    .quemsomos__grupo .quemsomos__subtitle
        font-size 24px
    
    .quemsomos__grupo
        display flex
        flex-direction column

    .quemsomos__grupo:before
        position static
        transform none
        top 0
        right auto
        width 80%
        height 60vw
        background-position 0 bottom
    
    .quemsomos__grupo .quemsomos__description,
    .quemsomos__numeros .numeros__list
        display flex
        flex-direction column
    
@media $mobile-medium
    .quemsomos__header .quemsomos__description
        max-width 99%
    