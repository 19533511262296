#menuToggle
  display none
  position relative
  top 0
  right 0
  
  z-index 1
  
  -webkit-user-select none
  user-select none

#menuToggle >input
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -10px;
  left: -8px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;

/*
 * Just a quick hamburger
 */
#menuToggle >span
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: $branco;
  border-radius: 3px;
  
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;

#menuToggle >span:first-child
  transform-origin: 0% 0%;

#menuToggle >span:nth-last-child(2)
  transform-origin: 0% 100%;

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle >input:checked ~ span
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);

/*
 * But let's hide the middle one.
 */
#menuToggle >input:checked ~ span:nth-last-child(3)
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle >input:checked ~ span:nth-last-child(2)
  transform: rotate(-45deg) translate(0, -1px);

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu
  display none
  position absolute
  width 300px
  margin -100px 0 0 -50px
  padding 2rem
  padding-top 125px
  
  background $cor-secundaria
  list-style-type none
  -webkit-font-smoothing antialiased
  /* to stop flickering of text in safari */
  
  transform-origin 0% 0%
  transform translate(100%, 0)
  
  transition all 0.5s cubic-bezier(0.77,0.2,0.05,1.0)

#menu 
    & >li
        padding 1rem 0.5rem 0.8rem
        font-size 0.875rem
        border-top 1px solid $azul-escuro2
        &:nth-last-child(3)
            border-bottom 1px solid $azul-escuro2
            margin-bottom 3rem
        &:nth-last-child(2), &:last-child
            border none
            padding 0
            font-size 1rem
        &:last-child
            margin 2rem 0

#menu >li 
    & .menu-actions
      font-size 0.875rem
      height auto
      text-transform uppercase
      color $branco
      padding 1rem 0 1rem 0.5rem
      align-items flex-start
      justify-content space-between
      width 100%
      border-top 1px solid $azul-escuro2
      border-bottom 1px solid $azul-escuro2
      &:first-child
          border-bottom none


/*
 * And let's slide it in from the left
 */
#menuToggle >input:checked ~ ul
  transform translate(-60%, 0)
  display block

@media $tablet-medium
    #menuToggle
        display block