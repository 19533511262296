// .form

.form__fieldset
    .input--fancy
        margin-bottom -1px

        &:last-of-type
            margin-bottom 0

.form__footer
    display flex
    align-items flex-end
    justify-content space-between

.form__obs
    font-size 11px