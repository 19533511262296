.footer
    padding 4em 0
    padding-bottom 1em
    background $cinza-300
    display grid
    grid-template-columns 1fr

.footer__container
    display flex
    flex-direction column
    font-size 12px

    &--last
        flex-direction row
        align-items center

.footer__novidades
    border 1px solid $branco
    border-radius 10px
    padding 1rem 2rem
    min-width 260px

.footer__novidades-descricao
    display flex
    align-items center
    justify-content space-between
    position relative
    gap 2rem
    margin-bottom 1rem
    &>p
        color $cor-secundaria
        font-weight 700
        font-size 2em
        letter-spacing -1px
        line-height 26px
        >b
            font-weight 400
    &>img
        position relative
        top -3rem
        right 0
        max-width 5rem

.footer__novidades-form
    display flex
    align-items flex-end
    justify-content space-between

.footer__localizacao
    display flex
    align-items center
    justify-content flex-start
    flex-direction row
    flex-wrap wrap
    gap 2rem
    row-gap 1rem
    padding 2rem 0
    padding-bottom 1rem
    margin 2rem auto
    border 1px solid $cinza-400
    border-left none
    border-right none
    
    &-item
        display grid
        grid-template-columns 1fr
        place-items start
        color $cinza-500
        font-size 14px
        max-width 230px
        flex: 0 0 33.33%

    &-cidade
        font-size 1rem
        font-weight 600

    &-telefone
        display inline-flex
        align-items center
        &:before
            content ''
            display inline-flex
            width 25px
            height 25px
            replace('icon-fone.svg')
            margin-right 1rem

    &-cnpj
        display inline-flex
        align-items center
        &:before
            content ''
            display inline-flex
            width 25px
            height 25px
            replace('icon-cnpj.svg')
            margin-right 1rem

    &-endereco
        display inline-flex
        align-items flex-start
        height 100%
        &:before
            content ''
            display inline-flex
            width 25px
            height 25px
            replace('icon-pin.svg')
            margin-right 1rem

.footer__horario
    flex 0 1 100%
    margin-top 2rem
    display inline-flex
    align-items center
    column-gap 4px
    &:before
        content ''
        display inline-flex
        width 25px
        height 25px
        replace('icon-clock.svg')
        background-size contain


.footer__logos
    position relative
    size 120px 80px
    replace('logo.png')
    background-size contain

    span
        display none

.footer__social.social
    display flex
    margin-top 30px
    margin-bottom 5em

    .social__link
        square 25px
        font-size 12px
    
    .social__link--instagram::after
        font-size 12px

.footer__sac
    .button
        width 100%
        max-width 260px
    
.footer__nav.nav
    display grid
    grid-template-columns 1fr 1fr 1fr 2fr
    position static
    height auto
    background-color transparent

    &:before
        display none
    
    .nav__list
        display block
    
    .nav__link
        display inline-flex
        align-items center
        justify-content space-between
        gap 0.5rem
        padding 5px 0
        color $cor-primaria
        font-size 14px
        line-height 18px
        font-weight 600
        width 200px

        &--icon:after
            content ''
            display block
            width 15px
            height 15px
            replace('icon-link.svg')

        &:hover
            color $cor-secundaria
            background transparent

    .nav__link--sublink:before
        content '•'
        margin-right 5px
        color $verde
    
    .nav__item--titulo
        margin-bottom 1em
        display block
        height 12px
        line-height 1.2
        color $ciano
        text-transform uppercase
        font-size 12px
        border-left 2px solid $verde
        padding-left 5px

    .nav__item--titulo:not(:first-child)
        margin-top 3em

    .nav__item--subtitulo
        margin-bottom .5em
        color $cinza-500
        text-transform uppercase
        font-size 12px
        font-weight 500

    .nav__col:nth-child(2),
    .nav__col:nth-child(3)
        .nav__link
            text-transform uppercase
        
        .nav__link--sublink
            text-transform none

.footer__sac
    display block
    line-height 1.4
    font-size 12px
    color $branco
    font-weight 400
    margin-bottom 15px
    
    em
        display block
        font-style normal
        font-weight 300

    &--fone
        margin-bottom 0

.footer__link
    display inline-block
    color $cor-secundaria
    text-decoration none
    font-size 14px
    padding 2px 0
    line-height 1.2rem

    // &--last
    //     margin-left 0.5rem
    //     padding-left 0.5rem
    //     border-left 1px solid $cinza-500

.footer__copyright
    font-size 10px
    font-style normal
    color $cinza-500
    margin-right auto

.footer__address
    margin-top 2em
    
@media $desktop-medium
    .footer__termos
        margin 0 40px

@media $tablet-large   
    .footer__termos
        margin 0 30px
        
    .footer__nav.nav
        gap 30px
        grid-template-columns repeat(3, 1fr)
    
        .nav__col:nth-child(1),
        .nav__col:nth-child(2),
        .nav__col:nth-child(3)
            grid-row 1/3

        .nav__col:nth-child(3)
            grid-row 1/2

        .nav__col:nth-child(4)
            grid-row 2/3
            grid-column 3/4

        .nav__col:last-child
            grid-row 3/4
            grid-column 1/4
    
    .footer__novidades
        width 100%
        margin 1rem auto
    .footer__localizacao
        justify-content center
        gap 4rem
        row-gap 2rem

@media $tablet-small
    .footer__nav.nav
        display flex
        flex-direction column
        justify-content center
        align-items center

        .nav__col:first-child
            align-self center
        .nav__link 
            justify-content center

            &--last
                text-align: center
            
    .footer__social.social
        margin 1rem 0
    .footer__novidades-descricao
        margin-bottom 1rem
    .footer__novidades-descricao>p
        font-size 1.8em
    .footer__container--last
        align-items flex-start
        flex-wrap wrap
        column-gap 1rem
    .footer__copyright
        flex 0 1 100%