.modal__content
  border 1px solid $preto !important
  border-top 1rem solid $cor-primaria !important
  &--erro
    border 1px solid $cor-erro !important
    border-top 1rem solid $cor-erro !important
  &--sucesso
    border 1px solid $cor-sucesso !important
    border-top 1rem solid $cor-sucesso !important

.modal__header
  padding-top 3rem !important
  position relative
  
.modal__header--erro:after
    position absolute
    top 0
    content ''
    bg('icon-erro.svg')
    width 45px
    height 40px
.modal__header--sucesso:after
    position absolute
    top 0
    content ''
    bg('icon-sucesso.svg')
    width 42px
    height 42px

.modal__title
    color $cor-primaria !important
    font-size 36px !important
    &--erro
        color $cor-erro !important
    &--sucesso
        color $cor-sucesso !important
.modal__actions
  display flex
  flex-direction column
  align-items center
  justify-content center
  gap 1rem

.modal__btn
  height 50px
  min-width 42px
  padding 1rem
  gap 1rem
  border 2px solid transparent
  box-sizing border-box
  font-family $font-primaria
  font-size 16px
  font-weight 600
  line-height 1
  letter-spacing 0
  text-align center
  text-transform none
  cursor pointer
  transition all 150ms ease
  width 75%
  min-width 200px
  border-radius 50px

.modal__confirm
  background $cor-primaria
  color #fff
  &:hover,
  &:focus
      color $branco
      background $cor-secundaria

.modal__erro
    color $cor-erro
    border 2px solid $cor-erro
    box-shadow none
    background transparent
    &:hover,
    &:focus
        border 2px solid $cor-erro
        color $branco
        background $cor-erro

.modal__sucesso
    color $cor-sucesso
    border 2px solid $cor-sucesso
    box-shadow none
    background transparent
    &:hover,
    &:focus
        border 2px solid $cor-sucesso
        color $branco
        background $cor-sucesso

.modal__cancel
  color $cor-secundaria
  border 2px solid $cor-primaria
  box-shadow none
  background transparent
  &:hover,
  &:focus
      border 2px solid $cor-secundaria
      color $branco
      background $cor-secundaria

@media $tablet-large
  .modal__btn
      height 42px
      font-size 14px