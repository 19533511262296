.radio-group
    font-weight: 900
    position: relative

    &__options
        margin-top: 1rem
        gap: 1rem
        display: flex
        align-content: center

        &__option
            display: flex
            gap: 8px
            flex-direction: row-reverse

            &__label
               cursor pointer

    & input
        margin: 0
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        padding: 0
        font: inherit;
        color: currentColor;
        width: 1.25em;
        cursor pointer
        height: 1.25em;
        border: 0.15em solid #C7C7C7
        border-radius: 50%;
        transform: translateY(-0.075em);
        display: flex;
        align-items: center
        place-content: center;

    & input::before
            content: "";
            width: 0.5em;
            background: #103E6F
            height: 0.5em;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;

    & input:checked::before
        transform: scale(1) 




