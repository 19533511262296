.tags
    display flex

.tag
    display flex
    align-items center
    justify-content center
    text-align center
    padding 8px 20px 5px
    font-weight 500
    color $preto
    font-size 12px
    line-height 1
    height 26px
    margin-right 5px
    border-radius 30px
    border 1px solid $cinza-300
    transition 0.3s ease

    &:hover
        a
            color $branco

.tag--comercial
    border-color $laranja

.tag--noticia
    border-color $cor-secundaria

.tag--aviso
    border-color $ciano

    &:hover
        background-color $ciano
        color $branco
    
.tag--novidade
    border-color $laranja

.tag--agronegocio
    border-color $verdeescuro

.tag--cidade
    border-color $cinza-300

    &--active
    &:hover
        background-color $cinza-300
        a
            color $branco