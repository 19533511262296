.share
  display flex
  justify-content flex-end

.share__label
  display inline-flex
  align-items center
  margin-right 1em
  color #726C6D
  font-size 12px
  line-height 1.25
  font-weight 400
  white-space nowrap

.share__list
  display flex
  gap 10px

.share__link
  display inline-flex
  align-items center
  justify-content center
  width 25px
  min-width 25px
  height 25px
  font-size 0
  color $branco
  background-color $cor-primaria
  border-radius 25px
  transition background-color 200ms ease

  &:hover
    color $branco
    background-color $preto

  &:after
    font-size 12px

  &--facebook:after
    fa-icon('facebook')

  &--twitter:after
    fa-icon('twitter')

  &--linkedin:after
    fa-icon('linkedin')

  &--whatsapp:after
    fa-icon('whatsapp')

  &--email:after
    fa-icon('envelope')

  &--instagram:after
    fa-icon('instagram')

@media $tablet-small
  .share
    flex-direction column
    justify-content flex-start
  
  .share__label
    margin-right 0
    margin-bottom 1em