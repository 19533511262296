.simulacao
    display grid
    grid-template-columns 1fr 1fr
    align-items start
    justify-content start
    margin 4rem 0
    position relative
    overflow hidden
    &:before
        position absolute
        content ''
        background $cor-secundaria
        top 0
        left -15rem
        width 18rem
        height 50%
        border-radius 6rem 0rem 8rem 6rem
    &:after
        position absolute
        content ''
        background $cor-primaria
        right -12rem
        bottom 0
        width 15rem
        height 75%
        border-radius 13.75rem 13.75rem 0rem 0.5rem

    >img
        width 80%
        margin auto 0
    
    &__header
        display flex
        flex-direction column
        gap 1rem

    &__titulo
        font-weight 400
        color $cor-secundaria
        font-size 2.5rem
        &>b
            font-weight 800

    &__subtitulo, &__contrato
        color $cinza-500
        line-height 1.5rem

    &__form
        display flex
        align-items flex-start
        flex-direction column
        gap 1.5rem
        width 65%
    
    &__double-input
        display flex
        align-items center
        justify-content space-between
        gap 1rem
        width 100%

@media $desktop-medium
    .simulacao>img
        width 95%

@media $tablet-medium
    .simulacao
        grid-template-columns 1fr
        &:before
            top 18rem
            left -10rem
            width 12rem
            height 35%
        &:after
            top 8rem
            bottom inherit
            right -12rem
            width 15rem
            height 65%
        >img
            width 50%
            margin 4rem 0
        
        &__form
            margin 0 auto
        &__titulo
            font-size 2rem

@media $tablet-small
    .simulacao
        &:before
            top 12rem
            left -8rem
            width 10rem
            height 25%
        &:after
            top 6rem
            right -8rem
            width 10rem
            height 45%

@media $mobile-large
    .simulacao
        &:before
            display none
        &:after
            display none

        >img
            width 75%
        &__titulo
            font-size 1.4rem
        &__double-input
            flex-direction column

@media $mobile-small
    .simulacao
        >img
            display none
        &__titulo
            font-size 1.2rem