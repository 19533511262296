// .nav

.nav__item--mobile
    display none

.nav__wrap
    display none

.nav__menu
    display none
    align-items center
    justify-content center
    width 60px
    height 60px
    background-color $cor-secundaria
    cursor pointer

    span
        display none
    
    &:hover
        background-color $cor-primaria

        .nav__bar
            background $branco

.nav__bars
    display flex
    align-items flex-start
    flex-direction column
    justify-content center
    margin-right 10px

.nav__bar
    display block
    width 18px
    height 2px
    margin 1.8px auto
    border-radius 2px
    background $cor-primaria

    &:nth-child(2)
        width 70%
        margin-left 0

// .theme-light .nav__bar
//     background-color transparent

.nav--open
    &:before
        opacity 1
        visibility visible

    .nav__wrap
        transform translateX(0)

    .nav__close
        z-index 29
        pointer-events auto
        opacity 1
        transition all .3s .3s ease

    .nav__menu
        opacity 0
        pointer-events none

@media $tablet-large 
    .nav__wrap
        display flex
        width 100%
        justify-content space-between


    .nav__menu,
    .nav__close
        position absolute
        z-index 30
        top 0
        left 0
        display flex
        justify-content center
        align-content center
        flex-direction column
        square 60px

        span
            display none

    .nav__close
        position fixed
        opacity 0
        pointer-events none
        background $cinza-200

        &:hover
            &:before
                transform none

            &:after
                transform none

        &:before,
        &:after
            content ''
            display block
            width 20px
            height 3px
            center()
            border-radius 2px
            background-color $cor-primaria
            transition all 200ms ease
            pointer-events none

        &:before
            transform rotate(45deg)

        &:after
            transform rotate(-45deg)

        &:hover
            &:before
                transform rotate(0)

            &:after
                transform rotate(0)

    .header
        .nav__wrap
            position fixed
            z-index 29
            top 0
            right 0
            flex-direction column
            align-items center
            justify-content flex-start
            width 100vw
            height 100vh
            overflow auto
            padding 90px 30px
            box-sizing border-box
            background-color $branco
            transform translateX(110%)
            transition all .3s $smooth

            &:before
                display none

        .nav__list
            display block
            width 100%

        .nav__item--titulo
            font-size 14px
            margin 30px 0 10px 10px
            color $cinza-400
            font-weight 400
            border-left 3px solid $cor-secundaria
            text-transform uppercase
            padding 3px 0 0 10px

        .nav__item
            width 80%
            justify-content center

            .button
                margin-bottom 20px
                font-size 14px

            &:before
                display none

            &:first-child
                &:after
                    content none    

        .nav__item--mobile
            display flex

        .nav__link
            display flex
            align-items center
            width 100%
            justify-content flex-start
            padding 0 10px
            min-height 45px
            text-align left
            font-size 14px
            font-weight 700
            text-transform uppercase
            letter-spacing 0
            box-sizing border-box
            border 0
            color $cor-primaria

            &:hover
                color $cor-primaria
                background none

    .nav--open .nav__close
        opacity 1
        pointer-events auto

    .nav--open .nav__wrap
        transform translateX(0)
