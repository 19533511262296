.social
    display inline-flex
    justify-content flex-start
    list-style none

.social__link
    position relative
    display inline-flex
    align-items center
    justify-content center
    square 25px
    margin 0 3px
    border-radius 30px
    text-decoration none
    font-size 13px
    box-sizing border-box
    transition all .15s ease
    color $branco
    background alpha($cor-secundaria, 70%)

    &--facebook:after
        fa-icon('facebook')

    &--twitter:after
        fa-icon('twitter')

    &--x-twitter:after
        content ""
        replace("x-twitter.svg")
        width 12px
        height 12px
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -50%)

    &--instagram:after
        fa-icon('instagram')

    &--youtube:after
        fa-icon('youtube-play')
    
    &--linkedin:after
        fa-icon('linkedin')
    
    &--link
        border none
        outline none
        cursor pointer
        &>span
            display none
        &:after
            content ""
            replace("copy-link.svg")
            width 14px
            height 14px
            position absolute
            top 52%
            left 50%
            transform translate(-50%, -50%)
    
    &--whatsapp:after
        fa-icon('whatsapp')

    &:hover
        background-color $cor-secundaria
        &:after
            color $branco

    span
        display none