
.sobre__referencia
    display grid
    grid-template-columns 1fr 1fr
    grid-template-rows auto auto
    gap 5em

.referencia__title
    padding-right 10%
    margin-bottom .75em
    font-size 50px

.referencia__body
    grid-row 1/3

.referencia__description
    line-height 1.6
    font-size 14px
    max-width 620px
    margin-bottom 3rem

.referencia__list
    display grid
    grid-template-columns 1fr 1fr
    gap 5em

.referencia__item
    display flex
    flex-direction column
    line-height 1.6
    font-size 14px
    align-items flex-start

    p
        max-width 260px
    
.referencia__logo
    display flex
    align-items center
    justify-content center
    height 60px
    margin-bottom 2em

@media $desktop-large
    .referencia__title
        font-size 38px
    
@media $desktop-small
    .referencia__list
        gap 3em

@media $tablet-large
    .sobre__referencia
        gap 3em

    .referencia__video
        .video__img
            width 100%
        
        .video__play em
            display none

@media $tablet-medium
    .sobre__referencia
        display flex
        flex-direction column
    
@media $tablet-small
    .referencia__list
        display flex
        flex-direction column
