.title
    font-size 60px
    font-weight 700
    line-height 1.1
    text-align center
    color $cor-primaria

@media $desktop-large
    .title
        font-size 50px

@media $desktop-medium
    .title
        font-size 40px
        line-height 1.2
    
@media $mobile-large
    .title
        font-size 30px
        line-height 1.4
    
@media $mobile-medium
    .title
        font-size 26px
