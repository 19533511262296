.com-a-canopus
    display flex
    align-items center
    justify-content flex-end
    height 99vh
    max-height 790px
    position relative
    overflow hidden

    &:before
        content ''
        position relative
        height 100%
        width 5%
        border-top-right-radius 5rem
        background $cor-primaria

    &__container
        position relative
        background $cor-primaria
        height 100%
        width 95%
        margin 0
        border-radius 5rem 7rem 0rem 0rem
        padding 2rem 10%
        padding-top 6rem
        display flex
        align-items flex-start
        justify-content center
        flex-direction column
        gap 10%
        &-swiper
            width 100%
            height 100%
            position relative
            overflow hidden

    &__titulo
        color $branco
        font-size 3.75rem
        line-height 3.5rem
        font-weight 400

.card__list
    width 100%
.comCanopusSwiper_card-button
    display none
    position absolute
    bottom 1rem
    top inherit
    padding 0
    height 40px
    width 40px
    background $branco
    &>i
        margin 0
    &:after
        display none
    &--left
        left 0
    &--right
        left 4rem
.card
    background $branco
    box-shadow 0px 4px 40px 0px rgba(0, 0, 0, 0.15)
    border-radius 1.25rem 0 1.25rem 0
    display flex
    align-items flex-start
    justify-content space-between
    flex-direction column
    gap 1rem
    padding 4rem 2rem
    min-width 243px
    min-height 370px
    width 20%
    max-width 312px
    max-height 438px

    &__img
        height 35%
    
    &__titulo
        color $cor-secundaria
        font-weight 600
        line-height 1.65rem
        font-size 1.5rem
    
    &__descricao
        color $cinza-500
        line-height 1.5rem

@media $desktop-large
    .com-a-canopus__container
        padding 6rem 2rem 1rem
    .com-a-canopus__titulo
        font-size 2.5rem
        line-height 2.5rem
        & b
            font-size 3rem
    .card
        &__titulo
            font-size 1.2rem
        &__descricao
            font-size 14px

@media $tablet-large
    .com-a-canopus__container
        gap 2rem
        overflow hidden
        padding-right 0
        border-top-right-radius 0
        padding 4rem 2rem 1rem
    .card
        &__titulo
            font-size 1.15rem
        &__img
            height 30%
@media $tablet-medium
    .com-a-canopus__container
        border-top-left-radius 3rem
    .card
        height 85%
    .comCanopusSwiper_card-button
        display block
        bottom 0


@media $mobile-large
    .com-a-canopus__titulo
        font-size 1.5rem
        line-height 2rem
        & b
            font-size 2rem