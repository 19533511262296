.politica__container
    display flex
    width 90%
    min-width 0
    margin-bottom 8rem
    gap 2rem

.politica__icon
    position relative
    height 1080px
    width 20%

.politica__icon-svg
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    width 100%
    height 100%
    max-width 194px
    max-height 194px
    min-width 115px
    min-height 115px
    replace('privacidade-icon.svg')
    background-size contain

    span
        display none

.politica__text
    font-size 1.25rem
    text-align justify
    padding 4rem
    position relative
    width 80%
    h1
        color $azul
        font-size 2.5rem
        margin-bottom 4rem
        text-align left

    h2,h3,h4
        display block
        color $preto
        font-weight 700
        margin-bottom 1rem
        margin-top 1rem
        text-align left

    p
        color $cinza-500
        line-height 30px
        font-size 1.25rem

        strong
            color $preto
            font-weight 700

        a
            text-wrap wrap
            word-break break-word
            overflow-wrap break-word

    ul
        padding-left 2rem
        li
            margin-bottom 1rem
            margin-top 1rem

.tabela__wrapper
    display block
    width 100%

.tabela__container
    display block
    overflow-x auto
    width 100%

.politica__tabela th,
.politica__tabela td
    padding 10px
    border 1px solid #ddd
    text-align left
    white-space nowrap

.politica__tabela tr:nth-child(odd) td
    background-color #f9f9f9

.politica__tabela tr:nth-child(even) td
    background-color #ffffff

@media screen and (max-width: 1000px)
    .politica__text
        width 100%

    .politica__icon
        display none

@media screen and (max-width: 768px)
    .politica__text
        padding 0.5rem

        ul
            padding-left 0.5rem
        li
            margin-bottom 1rem
            margin-top 1rem

    .politica__icon
        display none

