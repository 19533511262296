.trabalhe-conosco
    &__card
        display: grid
        margin-bottom: 8rem
        grid-template-columns: repeat(2, 1fr)

        &__btn
            position: relative
            
            &__text
                position: absolute
                height: 100%
                display: flex
                opacity 1
                align-items: center

                &--hide
                    opacity 0

            &__spinner
                position: absolute
                height: 100%
                opacity 0
                width 100%
                replace('spinner.svg')
                background-position: center
                text-indent: 0

                &--show
                    opacity 1

        &__title
            font-size: 2.5rem
            color: #103E6F
            padding: 0rem 1rem

            &__first-line
                font-weight: 100

            &__second-line
                font-weight: 900

        &__description
            margin: 2rem 0rem 3rem 0rem
            max-width: 530px
            padding: 0rem 1rem
            color: #666666
            font-size: 18px
            line-height: 20px

        &__description--sm
            font-size: 0.8rem
            max-width: 430px
            color: #666666
            margin-top: 1rem
            padding-bottom: 1.5rem
        &__content
            background: #FFFFFF
            border-radius: 0.625rem
            padding: 1.875rem 1.125rem

        &__second-column
            display: flex
            align-items: flex-end
            justify-content: flex-end

            &__image
                width 85%

        &__form
            display: flex
            flex-direction: column
            gap: 1.5rem

            & input
                border 1px solid $cinza-400
                box-shadow none

                &:focus
                    border 1px solid $cor-secundaria

            & label
                color: #666666

            & select
                color: #666666
                font-size: 1rem
                border 1px solid $cinza-400
                box-shadow none

            & button
                margin-top: 1rem

            &__first-col
                display: grid
                gap: 2.5rem
                grid-template-columns: 1fr 2fr

            &__second-col
                display: grid
                gap: 2.5rem
                margin-bottom: 2rem
                grid-template-columns: 1fr 1fr

                &__label-curriculo__text
                    color: #184d85
                    font-weight: 800
                    text-align: center

                &__container-curriculo
                    display flex
                    position: relative
                    justify-content: flex-end
                    align-items: flex-end

                &__label-curriculo
                    height: 70%
                    display: flex
                    cursor pointer
                    border: 1px solid #C7C7C7
                    border-radius: 50px
                    justify-content: center
                    align-items: center
                    width 100%
                    color: #184d85
                    transition: 0.2s
                    
                    &:hover
                        background: #184d85
                        transition: 0.2s

                    &:hover .trabalhe-conosco__card__form__second-col__label-curriculo__text
                        color: white
                        transition: 0.2s


                &__input-curriculo
                    width: 0.1px
                    height: 0.1px
                    opacity: 0
                    overflow: hidden
                    position: absolute
                    z-index: -1

@media $tablet-large
    .trabalhe-conosco
        &__card
            grid-template-columns: 1fr
            margin-bottom: 0rem

            &__form
                &__second-col
                    &__label-curriculo__text
                        font-size: 14px

            &__second-column
                align-items: center
                justify-content: center
                margin-top: 2rem

                &__image
                    width 85%

@media $mobile-large
    .trabalhe-conosco
        &__card
            &__form
                &__first-col, &__second-col
                    grid-template-columns: 1fr

                    &__container-curriculo
                        margin-bottom: 1rem

                    &__label-curriculo
                        height: 40px
                

@media $mobile-medium
        .trabalhe-conosco
            &__card
                width 100%
