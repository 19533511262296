.seja-fornecedor
    position relative
    margin-bottom 6rem

.seja-fornecedor__container
    display grid
    grid-template-columns 1fr
    place-items start center
    margin-bottom 6rem

.seja-fornecedor__secadastre
    border-radius 20px
    width 100%
    &-info
        display grid
        grid-template-columns 1fr
        place-items center start
        gap 2rem
        padding 2rem 3rem
        padding-bottom 4rem
        
    &-titulo
        color $cor-secundaria
        font-size 2.5rem
        font-weight 400
    &-descricao
        width 75%
        line-height 1.5rem

.seja-fornecedor__secadastre
    display grid
    grid-template-columns 1fr 1fr
    background $branco
    width 100%

    &-form
        width 100%
        display flex
        align-items flex-start
        justify-content flex-start
        flex-direction column
        gap 2rem
        & input
            border 1px solid $cinza-400
            box-shadow none
            &:focus
                border 1px solid $cor-secundaria
        &-two-col
            display inline-flex
            align-items flex-start
            justify-content space-between
            gap 2rem
            width 100%
            &>span
                font-size 0.75rem
                color $cinza-500

    &-img
        height 100%
        justify-self end
        &--retangulo
            display none
        


@media $tablet-medium
    .seja-fornecedor__container
        grid-template-columns 1fr
        justify-content center
        gap 4rem
    .seja-fornecedor__secadastre
        grid-template-columns 1fr
    .seja-fornecedor__secadastre-img
        display none
        &--retangulo
            display block
            width 100%

@media $mobile-large
    
    .seja-fornecedor__secadastre-form-two-col
        flex-direction column
    
    .seja-fornecedor__secadastre
        width 90%
        &-titulo
            font-size 2rem
        &-descricao
            width 100%
        &-info
            padding 2rem

    .corretor__nossos-corretores__titulo
        font-size 2rem

    .seja-fornecedor__secadastre-registro>p
        font-size 1rem
    
            
		