.descricao__content
    display none
    flex-direction column
    font-family 'Poppins'

.descricao__container
    margin-bottom 60px

.descricao__title
    color #107ec2
    font-size 40px
    font-weight 900
    line-height 107.5%
    margin-bottom 45px

.descricao__body
    display grid
    flex-direction row
    grid-template-columns repeat(2, 50%)

.descricao__vaga
    display grid
    align-items flex-start
    gap 10px

.vaga__sobre
    display block
    min-height 450px
    width 100%
    color $cinza-700
    background-color $cinza-card
    border-radius 6px
    padding 28px 35px

    .vaga__title
        text-transform uppercase
        font-size 18px
        font-weight 700
        line-height 26px
        color $azul
        margin-bottom 18px

    p, ul li
        line-height 26px

.vaga__requisitos

    strong
        display block
        text-transform uppercase
        font-size 18px
        font-weight 700
        line-height 26px
        color $azul

    ul
        list-style-type disc
        list-style-position inside

        li
            font-weight 600
            margin-top 10px

.vaga__compartilhar
    display flex
    align-items center
    justify-content flex-start
    margin-top 36px
    padding-top 21px
    border-top 1px solid #d9d9d9
    z-index 2

    .share__vaga
        display flex
        align-items center
        justify-content flex-start

        .share__label
            text-align left
            color $cinza-400

        .share__list a
            display flex
            align-items center
            color $branco
            background-color #88bc43
            border-radius 25px
            transition all .3s

            &:hover
                background-color #efb71e

.descricao__candidatar
    display flex
    flex-direction column
    align-items flex-end
    justify-content flex-start

.candidatar__content
    display flex
    flex-direction column
    align-items center
    justify-content flex-start

.candidatar__content
    width 422px

.candidatar__title
    width 100%
    align-content left
    margin-bottom 15px

    span
        color $cinza-600
        text-align left
        line-height 25px
        margin-bottom 14px

.button__candidatar
    all unset
    position relative
    display flex
    align-items center
    justify-content space-between
    height 70px
    width 100%
    color $branco
    background-color #88bc43
    margin-bottom 64px
    padding 0 50px 0 32px
    border-radius 6px
    box-sizing border-box
    cursor pointer
    transition all .3s

    &:hover
        color $branco
        background-color #efb71e

    span 
    
        &:nth-child(1)
            text-transform uppercase
            font-size 16px
            font-weight 700
            line-height 26px
        
        &:nth-child(2)
            font-size 16px
            font-weight 400
            line-height 22px

    &:after
        content ''
        position absolute
        top 20px
        right 22px
        color #fff
        font-size 24px
        width 18px
        transform rotate(-45deg)
        fa-icon('arrow-right')

.vaga__curriculo
    display flex
    flex-direction column
    align-items center
    height auto
    width 100%
    padding 29px
    color $branco
    background-color #006bac
    border-radius 6px
    z-index 2

.vaga__curriculo__content
    display flex
    height 115px
    margin-bottom 20px

.curriculo__content__enviar
    display flex
    flex-direction column
    align-items flex-start
    justify-content space-between

    h5
        text-align left
        font-size 20px
        font-weight 700
        line-height 28px
    
    span
        text-align left
        line-height 24px
        margin-bottom 3px

.vaga__enviar
    all unset
    text-align center
    font-weight 800
    color $branco
    background-color #88bc43
    width fit-content
    padding 16px 29px
    border-radius 55px
    cursor pointer
    transition all .3s

    &:hover
        color $branco
        background-color #efb71e

    &--voltar
        width 120px
        margin-top 20px
        padding 16px 36px
        background-color #efb71e

        &:hover
            opacity 0.8

.wave
    position relative
    height 70px
    width 100%

    &:before
        content ''
        position absolute
        bottom -2px
        left 0
        height 70px
        width 100%
        background-image url('/assets/img/wave.png')
        background-repeat no-repeat
        background-size cover

.veja__mais__content
    max-width 1602px
    width 90%
    padding 0 0 80px

.veja__mais
    display flex
    flex-direction column
    align-items center
    background-color $cinza-card

    .veja__mais--title
        display flex
        align-items flex-start
        height auto
        width 100%

        h5
            position relative
            text-align left
            color $azul
            font-size 32px
            font-weight 900
            line-height 50px
            margin-left 32px

            &:before
                content ''
                position absolute
                display inline-block
                top 5.5px
                left -20px
                height 28px
                width 7px
                background-color #88bc43
                margin-right 15px

    .veja__mais--instructions
        display block
        margin 2em 0 2em
        font-size 16px
        color $cinza-800

    .veja__mais--vagas
        padding 30px 70px
        height auto
        width 100%
        margin-bottom 80px

    .veja__mais--footer
        display flex
        align-items flex-start
        height auto
        width 100%

    .veja__mais--button
        font-weight 800
        font-size 16px
        padding 18px
        width 160px
        text-align center
        color $cinza-600
        background-color transparent
        border 3px solid #88bc43
        border-radius 55px
        cursor pointer
        transition all .3s

        &:hover
            color $branco
            background-color #88bc43



@media $tablet-large
    .descricao__body
        display flex
        flex-direction column
        grid-template-columns none

    .descricao__vaga
        margin-bottom 80px

    .vaga__sobre
        min-height auto

    .descricao__candidatar
        align-items flex-start

        .candidatar__content
            width 445px

@media $tablet-small
    .descricao__candidatar
        align-items center

        .candidatar__content
            width 70%

    .descricao__title
        font-size 36px

    .vaga__enviar
        width 200px
        padding 16px 0

@media $mobile-large
    .descricao__candidatar
        .candidatar__content
            width 100%

    .share__vaga
        flex-direction column
        justify-content center

    .vaga__curriculo__content
        flex-direction column
        align-items center
        height auto

        img
            width 150px
            margin-bottom 10px

    .curriculo__content__enviar
        align-items center

        h5
            font-size 24px
            text-align center
            margin-bottom 20px

        span
            font-size 18px
            text-align center

    .vaga__enviar
        width 200px
    
    .veja__mais
        .veja__mais--vagas
            padding 30px

@media $mobile-medium
    .vaga__enviar
        width 80%

    .veja__mais
        .veja__mais--vagas
            padding 30px 10px

        .veja__mais--footer
            justify-content center





