.novidades
    display flex
    align-items flex-start
    justify-content center
    flex-direction column
    margin 4rem auto
    gap 1rem

.novidades__header
    &-titulo
        font-weight 500
        font-size 3.75rem
        color $cor-secundaria

    &-btn
        margin-top 2rem
        color $cor-secundaria
        &:hover, &:focus
            background $cor-secundaria
            color $branco

.novidades__list
    display flex
    align-items flex-start
    justify-content center
    flex-direction column
    width 100%

.novidades__item
    display flex
    align-items center
    justify-content flex-start
    height 25vh
    width 100%
    gap 4rem
    padding 1rem 0
    border-bottom 1px solid $cinza-400

    &:first-child
        border-top 1px solid $cinza-400
    &::last-child
        border-bottom none


    >img
        aspect-ratio 4/3
        height 90%
        border-radius 10px
        object-fit cover
    
.novidade
    display flex
    flex-direction column
    height auto
    padding 1rem 0
    gap 2rem

    &__titulo
        color $cor-secundaria
        font-weight 600
        font-size 0.9rem
        letter-spacing 2px
        text-transform uppercase

    &__btn
        font-size 1.8rem
        line-height 2rem
        text-align left

    &__tempo
        font-size 0.75rem
        display inline
        letter-spacing 2px
        margin-top auto
        text-transform uppercase

@media $tablet-large
    .novidades__item
        gap 1rem
    .novidades__header-titulo
        font-size 2rem
    .novidade
        gap 1rem
        height auto
        &__btn
            height auto
            font-size 1.2rem
            line-height 1.5rem

@media $mobile-large
    .novidades__item
        position relative
        height auto
        width 100%
        flex-direction column
        >img
            height auto
            width 95%
            position relative
            top 3rem
            margin 0 auto
    .novidade
        gap 1rem
        width 100%
        margin-top 2rem
        word-break break-all
        &__btn
            height auto
        &__titulo
            position absolute
            top 2rem
            //bottom 17rem

@media $mobile-small
    .novidade__titulo
        bottom 11rem