.sustentaveis
    display grid
    grid-template-columns 1fr 1fr 1fr
    grid-row-gap 30px

.sustentaveis__title
    display block
    max-width 200px
    grid-column 1/2
    line-height 1
    font-size 30px
    text-transform uppercase
    font-weight 400

    strong
        font-weight 700

.sustentaveis__action
    grid-column 3/4
    justify-self flex-end

.sustentaveis__cta
    display none

.sustentaveis__list
    grid-row 2/3
    grid-column 1/4
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 20px

.sustentaveis__item
    background $turquesa
    border-radius 6px
    padding 50px 70px
    color $branco
    font-size 14px
    line-height 1.8

.sustentaveis__subtitle
    position relative
    font-size 24px
    margin-bottom 1em

    &:before
        content ''
        position absolute
        top 15px
        left -70px
        width 55px
        height 2px
        background $branco
    
.sobre__sustentaveis
    display block
    padding 8em 0
    background $cor-terciaria

    .sustentaveis__title
        position relative
        padding-left 20px
        color $branco
        text-transform none

        &:before
            content ''
            position absolute
            top 0
            left 0
            width 5px
            height 90%
            background-color $cor-primaria

    .sustentaveis__container
        display grid
        grid-template-columns 1fr 1fr 1fr
        grid-row-gap 30px

@media $desktop-large
    .sustentaveis__item
        padding 40px 50px 60px
    
    .sustentaveis__subtitle::before
        left -50px
        width 40px
    
    .sustentaveis__subtitle
        font-size 20px
        
@media $tablet-large
    .sustentaveis__subtitle
        line-height 1.1
    
    .sustentaveis__subtitle::before
        top 7px
        left -40px
        width 30px
    
    .sustentaveis__item
        padding 40px
    
@media $tablet-medium
    .sustentaveis
        display flex
        flex-direction column
        align-items flex-start

    .sustentaveis__title
        order 1

    .sustentaveis__list
        grid-template-columns 1fr
        order 2
    
    .sustentaveis__action
        order 3
        align-self center

@media $mobile-large
    .sobre__sustentaveis .sustentaveis__action
        display none
    
    .sobre__sustentaveis .sustentaveis__cta
        display flex
        width 100%
        order 3
    
    .sobre__sustentaveis .sustentaveis__container
        display flex
        flex-direction column
        
    .sobre__sustentaveis .clients
        margin-top 5em