.collapse
    display flex
    flex-direction column
    border-top 1px solid $cinza-100

    &--open
        .collapse__title
            color $azul
            border-bottom-color transparent
            padding-bottom 24px

            &::after
                transform rotate(180deg)

    &:last-child
        border-bottom 1px solid $cinza-100

.collapse__title
    cursor pointer  
    transition padding $time ease, color $time ease

    &::after
        content ""
        bg('faq-arrow.svg', center, right)
        position absolute
        display block
        background-position 0, 0
        height 15px
        width 20px
        right 30px
        transition all .3s ease

.collapse__content
    height 0
    overflow hidden
    transition all $time ease
    box-sizing border-box
