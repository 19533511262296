.header
    background $cor-secundaria
    transition all 300ms ease
    position relative
    z-index 100
    position fixed
    top 0
    width 100%
    & + section
        padding-top: 175px

.header__sticky
    .logo
        height 50px
        transition all 300ms ease
    .header__main
        padding-top 1rem

.header__bar
    display flex
    align-items center
    justify-content space-between
    padding 0.5rem 4rem
    border-bottom 1px solid $azul-claro

.header__buttons
    display flex
    align-items center
    gap 1rem

.header__social>.social__item>.social__link
    color $cor-secundaria
    background alpha($branco, 50%)
    &:hover
        background $branco
    &:hover:after
        color $cor-secundaria

.header__dropdown
    display flex
    align-items center

.header__dropdown-text
    color $branco
    align-content center

.header__dropdown-select
    display inline-flex
    align-items center
    justify-content flex-start
    position relative
    border-radius 32px
    border 2px solid $azul-claro
    cursor pointer
    &:before
        content ''
        position absolute
        left 1rem
        width 20px
        height 20px
        replace('icon-pin-orange.svg')
    &:after
        content ''
        position absolute
        right 1rem
        width 15px
        height 10px
        replace('arrow-down2.svg')
        margin-left auto
        transition all 300ms ease
        pointer-events none //evita que icone interfira no clique
    &.show:after
        transform rotate(180deg)

.header__dropdown-select
    margin-left 1rem
    &> select
        cursor pointer
        height 40px
        min-width 200px
        padding 0.5rem 2.5rem
        background transparent
        color $branco
        appearance: none;
        /* safari */
        -webkit-appearance: none;
        border none
        &:focus
            outline none
        &>option
            color $preto

.dropdown
    position relative
    display inline-block
    z-index 10
    width 145%
    min-width 250px
    border-radius 20px
    padding 3px 2rem
    color $branco
    cursor pointer
    //&:after
    //    content ''
    //    position absolute
    //    right 1rem
    //    width 15px
    //    height 10px
    //    replace('arrow-down2.svg')
    //    margin-left auto
    //    transition all 300ms ease
    //
    //&.show:after
    //    transform rotate(180deg)


.dropdown__toggle
    padding 10px
    z-index 100
    cursor pointer
    font-size 14px

.dropdown__menu
    display none
    position absolute
    background-color $branco
    min-width 190px
    font-size 14px
    border-radius 0 20px 20px 20px

.dropdown__menu-first
    border-radius 0 0 0 20px

.dropdown__submenu .dropdown__menu .dropdown__submenu-item:only-child
    border-radius 0 20px 20px 0
    background-color $branco

.dropdown__menu p
    color $preto
    padding 12px 16px
    text-decoration none
    display block

.dropdown__menu p:hover
    background-color $cinza-400

.dropdown__submenu
    position relative
    //width 100%

.dropdown__submenu .dropdown__menu
    top 0
    left 100%
    cursor pointer
    //margin-top -1px

.dropdown__submenu-item:first-child:hover p
    border-radius 0 20px 0 0

.dropdown__submenu-item:last-child:hover p
    border-radius 0 0 20px 20px

.dropdown__submenu-item:only-child:hover p
    border-radius 0 20px 20px 0

.dropdown__menu-first .dropdown__submenu:last-child:hover .dropdown__submenu-text /* acessar ultimo item do submenu */
    border-radius 0 0 0 20px

.show
    display block

.header__main
    display flex
    align-items center
    justify-content space-between
    padding 1.5rem 0 0.5rem

.header__navigation
    &-list
        display flex
        gap 2rem
    &-link
        color $branco
        //border-bottom 2px solid transparent
        transition all 300ms ease
        position relative
        &:after
            content ""
            position absolute
            left 0
            bottom -4px
            width 100%
            height 2px
            border-radius 50px
            background-color $cor-primaria /* Cor da borda */
            transform-origin center top
            transform scaleX(0)
            transition transform 0.3s ease-in-out
        &:hover
            color $branco
            border-bottom-color $cor-primaria
            &:after
                transform scaleX(1)

.header__fale-conosco
    position relative
    &-popup
        display none
        opacity 0
        position absolute
        right 0
        top 4rem
        z-index 10
        width 550px
        padding 1rem
        background $branco
        border-radius 10px
        transition all 2s ease-in-out

.header__fale-conosco-popup
    &:after
        content ''
        position absolute
        bottom 100%
        right 10%
        margin-left -10px /* metade da largura da ponta */
        border 10px solid transparent
        border-color transparent transparent $branco transparent
    &__list
        display flex
        align-items center
        justify-content space-between
    &__item
        display flex
        flex-direction column
        align-items center
        gap 0.5rem
        justify-content center
        text-align center
        font-size 12px
        color $cinza-500
        padding 0 1rem
        &>.telefone-mask
            color $cor-secundaria
            font-size 14px
            font-weight 500
        &:nth-child(2)
            padding 0 2rem
            border-left 2px solid $cinza-400
            border-right 2px solid $cinza-400
    &--open
        display block
        opacity 1


.header__item
    height 100%

.header__link
    display flex
    align-items center
    height 100%
    color $branco
    padding 10px 18px
    font-weight 600
    text-transform uppercase
    font-size 12px

    &:hover
        color $branco
        background $cor-primaria

.header__dropbtn
    position relative

    &:before
        content ''
        position absolute
        top 42%
        right 0
        size 12px 5px
        replace('icon-dropdown.svg')

.header__dropbtn:has(ul.dropdown:hover)
    background-color $cor-primaria

.header__dropbtn:hover .dropdown
    display block

.header__cta
    font-weight 600

@media $tablet-large
    .header__navigation-list
        gap 1rem
    .header__navigation-link
        font-size 14px

@media $tablet-medium
    .header__list
        display none

    .header__bar
        padding 0.5rem 2rem
        & >ul
            display none

    .header__navigation
        display none

    .header__select
        margin-left 0
    .header__buttons > button, .header__buttons > a
        display none

    .header__simule,
    .header__cta
        padding 0 30px

@media $mobile-large
    .header__dropdown-text
        display none
    .header__dropdown-select
        margin 0
    .header__fale-conosco-popup
        width 100%
        &__list
            flex-direction column
            gap 1rem
    .header__fale-conosco-popup__item:nth-child(2)
        border none
        border-top 2px solid $cinza-400
        border-bottom 2px solid $cinza-400
        padding 1rem 0
    .header__simule
        display none

    .header__social
        margin-left 20px

        .social__link
            background $branco

@media $mobile-medium
    .header__cta
        max-width 140px

    .dropdown__menu-first
        position absolute
        width 100%
        top 100%
        left 0
        z-index 1000

    .dropdown__submenu .dropdown__menu
        top auto
        left auto
        position relative
        margin-top 10px
        display none

    .dropdown__menu.show
        display block

    .dropdown__menu-first
        border-radius 0 0 20px 20px

    .dropdown__submenu-text,
    .dropdown__submenu-item
        height 44px

    .dropdown__menu p
        align-content center

    .dropdown__menu-first .dropdown__submenu:last-child:hover .dropdown__submenu-text /* tirar border radius do ultimo estado */
        border-radius 0

    .dropdown__submenu .dropdown__menu .dropdown__submenu-item:last-child
        border-radius 0
        border-bottom 2px solid $cinza-400

    .dropdown__submenu:last-child .dropdown__menu:last-child .dropdown__submenu-item:last-child
        border-bottom 0
        border-radius 0 0 20px 20px


@media $mobile-small
    .header__social.social
        margin-left auto
        margin-right 30px

    .header__cta
        display none
    .header__bar
        padding 0.5rem 1rem
