.container
    max-width 1440px
    width 90%
    margin 0 auto

@media $tablet-large
    .container
        width 84%
        
@media print
    .container
        max-width none
        width auto
