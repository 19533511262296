bg(image = 'sprite.png', $left = center, $top = center, $color = transparent, $repeat = no-repeat)
    background $color url('./img/'image) $repeat $left $top

bgi(image = 'sprite.png')
    background-image url('./img/'image)

bgsize(arguments)
    background-size arguments

center(y = 0, x = 0)
    position absolute
    top 50%
    left 50%
    margin (@height / -2)+y 0 0 (@width / -2)+x

centerx(x = 0, pos = absolute)
    position pos
    left 50%
    margin-left (@width / -2) + x

centery(y = 0)
    position absolute
    top 50%
    margin-top (@height / -2) + y

font-family()
    font-family arguments
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale

// opacity(n)
//     -ms-filter \"unquote('progid:DXImageTransform.Microsoft.Alpha(Opacity=' + n * 100 + ')')\"
//     -khtml-opacity n
//     -moz-opacity n
//     opacity n

placeholder(arguments)
    &::-webkit-input-placeholder
        color arguments
    &::-moz-placeholder
        color arguments
    &:-ms-input-placeholder
        color arguments
    &:-moz-placeholder
        color arguments

replace(image = 'sprite.png', left = 0, top = 0, color = transparent, size = contain)
    display block
    border 0
    background color url('./img/'image) no-repeat left top
    background-size size
    text-indent -9999px
    overflow hidden

size(width = 5px, height = 5px)
    width width
    height height

square(squaresize = 5px)
    size(squaresize, squaresize)
