.banner
    // height 80vh
    min-height 800px
    color $branco
    background $cor-terciaria
    transition all 300ms ease

.home--instalacao .banner
    background-color $laranja

.home--assinatura .banner
    background-color $turquesa

.home--locacao .banner
    background-color $verde

.banner__container
    display flex
    max-width 1280px

.banner__content
    position relative
    z-index 2
    padding 100px 0

.banner__ilustration
    position relative
    z-index 1
    margin-left -5%
    min-width 800px

.banner__thumb
    display none

.banner__img
    position absolute
    top 0
    left 0
    display block
    transform translateX(200px)
    opacity 0
    transition all 200ms ease-in-out
    pointer-events none

.banner__title
    position relative
    min-width 540px
    margin-bottom 1em
    font-weight 800
    text-align left
    line-height 1.1
    color $branco
    overflow hidden

    em
        // display none
        position absolute
        top 60px
        left 0
        display inline-block
        margin-bottom 10px
        padding 10px 20px 5px
        font-style normal
        line-height .9
        background $amarelo
        transform translateX(-600px)
        transition all 200ms ease-in-out
        overflow hidden

    span
        display block
        padding 0 10px

.home .banner__img--default
    transform translateX(0)
    opacity 1

.home .banner__category--default
    transform translateX(0)

@media $notmobile
    .home--instalacao
        .banner__category--default
            transform translateX(-600px)

        .banner__category--sustentabilidade
            transform translateX(0)

        .banner__img--default
            transform translateX(200px)
            opacity 0

        .banner__img--instalacao
            transform translateX(0)
            opacity 1

    .home--assinatura
        .banner__category--default
            transform translateX(-600px)

        .banner__category--economia
            transform translateX(0)

        .banner__img--assinatura
            transform translateX(0)
            opacity 1

        .banner__img--default
            transform translateX(200px)
            opacity 0

    .home--locacao
        .banner__category--default
            transform translateX(-600px)

        .banner__category--limpa
            transform translateX(0)

        .banner__img--locacao
            transform translateX(0)
            opacity 1

        .banner__img--default
            transform translateX(200px)
            opacity 0

.banner__aside
    position absolute
    top calc(40% - 185px)
    right 0

.banner__item
    position relative
    max-width 320px

    &:after
        content ''
        position absolute
        top calc(50% - 5px)
        right 10%
        size 8px 12px
        replace('arrow-right.svg')
        pointer-events none
        transition all 150ms ease

    .banner__subtitle
        color $branco
        transition all 200ms ease

    .banner__link
        display block
        border-radius 5px
        padding 30px 80px 30px 30px
        margin-bottom 5px
        background rgba(0,0,0,.1)
        transition all 200ms ease

        &:hover
            background rgba(0,0,0,.3)

    &:hover:after
        transform translateX(10px)

.banner__label
    display block
    margin-bottom 15px
    font-size 10px
    letter-spacing 2px
    font-weight 400
    text-transform uppercase
    color alpha($branco, 60%)

.banner__subtitle
    line-height 1.2
    font-size 16px
    font-weight 700

.banner__bullet--instalacao
    grid-column 2/3

.banner__bullet--assinatura
    grid-column 3/4

.banner__bullet--locacao
    grid-column 4/5

.banner__bullets
    display grid
    grid-template-columns repeat(4, 1fr)
    justify-content flex-end
    margin-top 50px

    .banner__link
        position relative
        display block
        padding 30px 70px
        color $branco

        *
            position relative
            z-index 2

        &:before
            content ''
            position absolute
            z-index 1
            top 0
            left 0
            square 100%
            background-image linear-gradient(360deg, rgba(0,0,0,0) 10.29%, rgba(0,0,0,0.10) 44.74%, rgba(0,0,0,0) 80.25%)
            opacity 0
            transition all 200ms ease
            pointer-events none

.banner__bullet--instalacao:after
    background $amarelo

.banner__bullet--assinatura:after
    background $turquesa

.banner__bullet--locacao:after
    background $verdeclaro

.banner__bullet
    position relative
    max-width 480px

    &:before
        content ''
        left 0
        top 60px
        position absolute
        display block
        width 1px
        height 100%
        background-image linear-gradient(180deg, rgba(255,255,255,.7) 0%, rgba(255,255,255,0) 100%)
        opacity .3
        transition all 150ms ease

    &:after
        content ''
        left 0
        top 30px
        position absolute
        display block
        width 5px
        height 30px
        transition all 150ms ease

    .banner__subtitle
        display block
        margin-bottom .5em
        font-size 40px
        font-weight 700

.banner__more
    position relative
    display block
    margin-top 30px
    text-transform uppercase
    font-size 10px

    em
        position absolute !important
        top 0
        left 0
        display block
        font-style normal
        letter-spacing 1px
        // transform translateX(-10px)
        // opacity 0
        transition all 200ms ease

    &:after
        content ''
        size 50px 14px
        // transform translateY(-3px) translateX(0)
        transform-origin top left
        replace('arrow-right-big.svg')
        transition all 200ms ease

.banner__more em
    transform translateX(0)
    opacity 1

.banner__more:after
    transform translateY(-3px) translateX(80px)

@media $notmobile
    .banner__more em
        transform translateX(-10px)
        opacity 0

    .banner__more:after
        transform translateY(-3px) translateX(0)

    .banner__bullet:hover
        .banner__more em
            transform translateX(0)
            opacity 1

        .banner__more:after
            transform translateY(-3px) translateX(80px)

        .banner__link:before
            opacity 1

.banner__bullet:hover:before
    opacity 1

.banner__bullet:hover:after
    background $branco

.banner__description
    font-size 14px
    color alpha($branco, 80%)
    line-height 1.8

@media $desktop-large
    .banner
        min-height 700px

    .banner__title
        min-width 480px
        line-height 1.25

    .banner__img
        max-width 550px
        max-height 400px

    .banner__content
        padding 90px 0 60px

    .banner__item .banner__link
        padding 20px 80px 20px 20px

    .banner__bullets
        margin-top 0

        .banner__link
            padding 30px 50px

    .banner__bullet .banner__subtitle
        font-size 30px

@media $desktop-medium
    .banner__title
        font-size 40px
        min-width 360px

    .banner__img
        max-width 555px
        max-height 400px

    .banner__ilustration
        min-width 520px
        pointer-events none

    .banner__title em
        top 46px

@media $desktop-small
    .banner__bullet .banner__subtitle
        font-size 26px

@media $tablet-large
    .banner__item
        max-width 255px

    .banner__img
        max-width 370px
        max-height 280px

    .banner__ilustration
        margin-left -7%

    .banner__aside
        top 18%

    .banner__subtitle
        font-size 14px

    .banner__bullets
        max-width 84%
        margin 0 auto
        grid-template-columns 1fr

    .banner__bullets .banner__link
        padding 40px 50px 40px 0

    .banner__bullet
        max-width none

        .banner__link
            padding-right 35vw

    .banner__thumb
        position absolute !important
        top 0
        right 0
        display flex
        justify-content flex-end
        width 20vw
        height 100%

    .banner__bullet--assinatura,
    .banner__bullet--instalacao,
    .banner__bullet--locacao
        grid-column auto

    .banner__bullet:before
        bottom 0
        top auto
        width 100%
        height 1px

    .banner__bullets .banner__link::before
        display none

    .banner__more em
        transform translateX(0)
        opacity 1

    .banner__more:after
        transform translateY(-3px) translateX(80px)

    .banner__bullet:after
        display none

@media $tablet-medium
    .banner__aside
        display none

    .banner__container
        flex-direction column-reverse

    .banner__ilustration
        display flex
        align-items center
        justify-content center
        min-width auto
        margin-left auto
        margin-right auto
        padding 50px 0

        .banner__img--default
            position static

        .banner__img--assinatura,
        .banner__img--locacao,
        .banner__img--instalacao
            display none

    .banner__content
        padding 0 0 30px

    .banner__action
        width 100%
        max-width 414px

@media $tablet-small
    .banner__thumb
        display none

    .banner__bullet .banner__link
        padding-right 10%

    .banner__img
        max-width 325px
        max-height 245px

    .banner__title
        min-width auto

@media $mobile-small
    .banner__title
        font-size 36px

        em
            top 42px
