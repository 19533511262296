.form-header
    margin-bottom: 1.5rem

    &__title
        font-size: 40px
        color: #103E6F

    &__description
        color: #666666
        font-size: 18px
        line-height: 26px
        padding-top: 10px
        max-width: 460px
        width 100%

@media $mobile-large
    .form-header

        &__title
            font-size: 30px
        
        &__description
            font-size: 16px
        
