.home
    transition all 300ms ease
    background $cinza-100

.rdstation-popup-position-center.floating-button.floating-button--close
    display none !important

.home__hero
    position relative

    .hero__imagem--desktop
        display block

    .hero__imagem--mobile
        display none

@media $tablet-small
    .home__hero
        position relative

        .hero__imagem--desktop
            display none

        .hero__imagem--mobile
            display block


