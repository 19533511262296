.filtro
    display flex
    align-items center
    margin-bottom 5em

.filtro__label
    margin-right 1em

.filtro__link
    color $verdeescuro
    border-color lighten($cinza-300, 50%)

.filtro__link--active
    border-color $cinza-200
    background-color $cinza-200

.filtro__link:hover:not(.filtro__link--active)
    color $branco
    border-color $cor-primaria
    background-color @border-color

@media $tablet-medium
    .filtro
        flex-direction column
        align-items flex-start
    
    .filtro__label
        margin-bottom 1em

@media $mobile-medium
    .filtro
        overflow hidden

    .filtro__list
        width 100%
        overflow auto
