// typography
$font-primaria = 'Poppins', sans-serif
$font-secundaria = 'Poppins', sans-serif

*
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale
    box-sizing border-box

body
    color $cinza-600
    font-weight 400
    font-family $font-primaria

p
    // line-height 1.8em
    margin-bottom 1.5em

    &:last-of-type
        margin-bottom 0

ul
    list-style none
