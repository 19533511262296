.radio
    position relative
    display inline-flex
    align-items center
    justify-content flex-start
    height 20px
    padding-left 21px
    margin-right 45px
    margin-bottom 20px
    cursor pointer

    &.input__label
        font-size 14px
        color $branco
        font-weight 400
        line-height 1.6
        letter-spacing normal
        text-transform none

.radio__field
    position absolute
    opacity 0
    cursor pointer
    height 0
    width 0

.radio__mark
    content ''
    position absolute
    top 0
    left 0
    height 15px
    width 15px
    border 2px solid alpha($branco, 60%)
    border-radius 50%
    box-sizing border-box

.radio__mark:after 
    content ""
    position absolute
    display block
    top 1px
    left 1px
    background $verde
    size 9px 9px
    border-radius 50%
    opacity 0
    transform scale(0.5)
    transition all 150ms ease

.radio__field:checked ~ .radio__mark:after 
    display block
    opacity 1
    transform scale(1)
