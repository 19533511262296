.projetos
    padding-bottom 8em
    overflow hidden

.projetos__header
    display grid
    grid-template-columns repeat(3, 1fr)

.projetos__title
    display block
    grid-row 1/2
    grid-column 1/2
    margin-bottom 2em
    text-transform uppercase
    font-size 9px
    color $cor-terciaria
    letter-spacing 2px
    font-weight 400

.projetos__subtitle
    grid-row 2/3
    grid-column 1/3
    margin-bottom .75em
    font-size 40px
    font-weight 400
    color $cor-terciaria

    strong
        font-weight 700

.projetos__action
    grid-column 3/4
    grid-row 2/3
    justify-self flex-end

.projetos__swiper
    overflow visible !important
    display flex
    flex-direction column

.projetos__item
    max-width 720px
    object-fit cover

.projetos__nav
    display flex
    flex-direction row-reverse
    justify-content flex-end
    gap 10px
    margin-top 30px
    
.projetos__navigation--next, 
.projetos__navigation--prev
    top unset !important
    position unset !important
    margin-top 0 !important

.projetos__navigation
    width 50px !important
    height 50px !important
    display flex
    align-items center
    justify-content center
    square 50px
    border-radius 50px
    border 1px solid $cor-primaria
    background transparent
    transition all 200ms ease

    span 
        display none

    &:after
        content ''
        square 50px
        replace('arrow-left.svg', center, center)
        transform rotate(0)
        background-size 9px auto
        object-fit cover

    &:hover
        border-color $verde
        background-color $verde

        &:after
            filter brightness(0) invert(1)

    &--disabled
        border-color $cinza-300
        pointer-events none

        &:after
            filter brightness(0)
            opacity 0.3

.projetos__navigation--next
    transform rotate(180deg)

.projeto__link
    position relative
    display flex
    align-items stretch
    justify-content space-between
    max-width 720px
    // min-height 512px
    color $branco
    background $cinza-400
    border-radius 7px
    cursor grab
    overflow hidden

.projeto__thumb
    display flex
    align-items center
    justify-content center
    width 100%
    overflow hidden
    max-height 376px

    &:after
        content ''
        position absolute
        z-index 2
        square 100%
        background-image linear-gradient(0deg, #000000 10.67%, rgba(0, 0, 0, 0) 63.24%)
        opacity .9
        transition all 300ms ease

.projeto__link:hover
    .projeto__detalhes
        color $branco

    .projeto__img
        transform scale(1.1)

    .projeto__thumb:after
        opacity 1

.projeto__img
    display block
    width 100%
    height 100%
    max-height 512px
    object-fit cover
    transition all 300ms ease-in-out

.projeto__content
    z-index 2
    position absolute
    display flex
    flex-direction row
    width 100%
    justify-content space-between
    align-items flex-end
    bottom 40px
    padding 0 50px

.projeto__info__main
    align-self baseline
    display flex
    flex-direction column
    gap 1.5rem

.projeto__name
    font-size 16px
    font-weight 800

.projeto__tags,
.projeto__detalhes
    display flex
    
.projeto__detalhes
    flex-direction column
    line-height 1.4

.projeto__economia
    margin-top 1rem
    display flex
    flex-direction column
    position relative

    &:before
        content ''
        position absolute
        left 0
        width 5px
        height 100%
        background-color $cor-primaria

.projeto__economia__description
    color $cor-secundaria
    margin-left 15px

    &:first-child
        font-size 14px

    &:last-child
        font-size 16px

.projeto__tags
    justify-content flex-end

    .tag
        color $branco

    .tag--empresarial
        border-color $laranja
    
    .tag--cidade
        border-color $cinza-400

.projetos__cta
    display none

@media $desktop-medium
    .projeto__content
        padding 0 40px

    .projeto__tags
        flex-direction column
        align-items flex-end

        .tag
            margin-bottom 5px

@media $tablet-large
    .projetos
        padding 0

    .projetos__subtitle
        font-size 32px
    
    .projetos__action
        display none

    .projetos__item
        max-width 520px
    
    .projetos__cta
        display inline-flex
        margin-top 2em

@media $tablet-small
        
    .projetos__header
        display flex
        flex-direction column
        
    .projetos__subtitle
        font-size 24px
    
    .projetos__title
        font-size 12px
        color $cinza-400

    .projetos__action 
        display none

    .projeto__content
        padding 0 30px
        flex-direction column
        align-items baseline
    
    .projeto__tags
        align-items flex-start
        flex-direction row
        margin-bottom 20px
    
    .projeto__detalhes
        font-size 14px

@media $mobile-medium
    .projetos__item
        max-width 360px

    .projeto__img
        min-height 450px

    .projeto__tags
        flex-direction column