.checkbox
    position relative
    display flex
    align-items center
    justify-content flex-start
    height 25px
    padding-left 45px
    margin-bottom 12px
    cursor pointer
    font-size 12px
    letter-spacing .1em
    text-transform uppercase
    font-weight 500

.checkbox__field
    position absolute
    opacity 0
    cursor pointer
    height 0
    width 0

.checkbox__mark
    content ''
    position absolute
    top 0
    left 0
    height 25px
    width 25px
    // background-color #eee
    border 1px solid $cinza-400
    border-radius 2px

.checkbox__mark:after 
    content ""
    position absolute
    display none
    left 8px
    top 4px
    width 5px
    height 10px
    border solid $cor-primaria
    border-width 0 3px 3px 0
    -webkit-transform rotate(45deg)
    -ms-transform rotate(45deg)
    transform rotate(45deg)

.checkbox__field:checked ~ .checkbox__mark:after 
    display block
