.erro404
    background $cinza-200

.erro404__content
    display grid
    grid-template-columns 1fr    
    width 100vw
    height 100vh
    
.erro404__container
    display flex
    flex-direction column
    justify-content center
    align-items center

.erro404__logos.logo
    height 130px
    width 180px

.erro404__title
    display inline-flex
    flex-direction column
    margin-top 2rem
    font-size 1.875rem 
    font-weight 700
    color $cor-secundaria

.erro404__title strong
    font-size 10rem;

.erro404__subtitle
    font-weight 700
    font-size 1.5rem
    color $cor-secundaria
    margin-bottom 4rem

.erro404__description
    text-align center
    color $cinza-500
    font-size 1rem
    line-height 1.5rem
    margin-bottom 2rem
    >p
        margin 0

.erro404__action
    margin-top 4em
    max-width 250px
    width 100%
    height 50px

@media $tablet-large
    .erro404__content
        display flex
        flex-direction column
        padding 2em 0 15vw
        box-sizing content-box

    .erro404__container
        align-items center
        text-align center
        padding-left 0
    
    .erro404__logos.logo
        height 75px
        width 90px
    
    .erro404__title
        margin-top 0.5rem
    
    .erro404__subtitle
        font-size 20px
        margin-bottom 2rem

    
@media $mobile-large
    .erro404__title
        font-size 1.5rem