.corretor
    position relative
    margin-bottom 6rem
.corretor__header
    background: $cor-secundaria
    margin-bottom 8rem
    position relative
    top -1rem
    &-container
        position relative
        top 3.5rem
    &-titulo
        background $branco
        width fit-content
        padding 0.5rem 1rem
        color $cor-secundaria
        font-size 2.5rem
        text-transform uppercase
        font-weight 600
    &-breadcrumb
        width fit-content
        background $branco
        padding 0.5rem 1rem
        margin-top 2px
        & :first-child
            color $cor-primaria
        & > p
            color $cor-secundaria
            margin 0

.corretor__container
    display grid
    grid-template-columns 1fr 1fr
    place-items start center
    margin-bottom 6rem

.corretor__login, .corretor__pasta-online, .corretor__secadastre
    border-radius 20px
    width 75%
    &-info
        display grid
        grid-template-columns 1fr
        place-items center start
        gap 2rem
        padding 2rem 3rem
        padding-bottom 4rem
        
    &-titulo
        color $cor-secundaria
        font-size 2.5rem
        font-weight 400
    &-descricao
        width 75%
        line-height 1.5rem

.corretor__login
    display block
    background $branco

    &-form
        width 100%
        display inline-flex
        align-items flex-end
        justify-content space-between
        gap 2rem
                
    &-form :first-child
        flex 1
        &>input
            border 1px solid $cinza-400
            box-shadow none
            &:focus
                border 1px solid $cor-secundaria

    &-registro
        display flex
        align-items center
        justify-content space-between
        border-top 1px solid $cinza-400
        gap 2rem
        padding 2rem 3rem
        &>p
            font-size 1.5rem
            color $cinza-500
            font-weight 600
.corretor__secadastre
    display none
    background $branco

    &-form
        width 100%
        display flex
        align-items flex-start
        justify-content flex-start
        flex-direction column
        gap 2rem
        & input
            border 1px solid $cinza-400
            box-shadow none
            &:focus
                border 1px solid $cor-secundaria
        &-two-col
            display inline-flex
            align-items flex-start
            justify-content space-between
            gap 2rem
            width 100%
            &>span
                font-size 0.75rem
                color $cinza-500

    &-registro
        display flex
        align-items center
        justify-content space-between
        border-top 1px solid $cinza-400
        gap 2rem
        padding 2rem 3rem
        &>p
            font-size 1.5rem
            color $cinza-500
            font-weight 600

.corretor__pasta-online
    border 1px solid $branco
    &>img
        width 100%

.corretor__nossos-corretores
    &__titulo
        color $cor-secundaria
        font-size 2.5rem
        font-weight 400
        margin-bottom 2rem
    &__list
        display flex
        align-items center
        justify-content space-between
        flex-wrap wrap
        gap 4rem
        row-gap 2rem

.corretor__nossos-corretores__item
    display flex
    align-items flex-start
    justify-content center
    flex-direction column
    position relative
    padding 1rem 0
    gap 0.5rem
    &-titulo
        color $cor-secundaria
        font-size 1.125rem
        font-weight 600
        margin 0
    &-wpp
        font-size 1.125rem
        font-weight 600
    &-cargo
        margin-top 1rem
        color $cinza-500
        text-transform uppercase
    &:before
        content ''
        position absolute
        left 0
        top 0
        width 15%
        height 4px
        background $branco

.corretor__usuario
    position relative
    display flex
    align-items center
    justify-content flex-end
    bottom 6rem
    font-weight 400
    color $cinza-500

    &-titulo
        margin-right 0.5rem
        padding-right 0.5rem
        padding-top 2px
        border-right 1px solid $cinza-400
    &-logout
        font-weight 600

.corretor__tabela
    background $branco
    border-radius 10px
    display grid
    grid-template-columns 1fr
    gap 2rem
    place-items start
    padding 2rem 3rem
    &-filtro, &-body
        width 100%
    &-filtro
        display flex
        align-items center
        justify-content flex-start
        gap 2rem

        &-select
            flex-direction row
            width fit-content
            &:last-child>select
                width 400px
        &-select>label
            font-size 1rem
            font-weight 400
            color $cinza-500
            width fit-content
        &-select>select
            border 1px solid $cinza-400
    &-main
        display grid
        grid-template-columns 1fr
        gap 2rem
        place-items start
        width 100%
    &-cidade
        color $cor-secundaria
        font-size 1.125rem
        font-weight 600
    &-head
        width 100%
        display grid
        grid-template-columns 3fr 1fr 1fr
        border-bottom 1px solid $cinza-400
        color $cinza-500
        padding 0.5rem
        & :last-child
            position relative
            &:before
                content: ''
                position absolute
                square 12px
                replace('arrow-up.svg')
                display inline-block
                left -1rem


    &-list
        padding 0.5rem 0
        display grid
        grid-template-columns 1fr
        gap 4px
    &-item
        width 100%
        border-radius 10px
        display grid
        grid-template-columns 3fr 1fr 0.5fr 0.5fr
        align-items center
        padding 4px
        padding-left 1rem
        background $cinza-200
        color $cinza-500
        &-arquivo
            color $cor-secundaria
            font-weight 600
        &-btn
            justify-self end
            height 2.5rem
            width 4rem

        


@media $tablet-large
    .corretor__tabela-head
        grid-template-columns 1fr 1fr 1fr
    .corretor__tabela-item
        grid-template-columns 1fr 1fr 0.5fr 0.5fr
    .corretor__tabela-filtro
        flex-wrap wrap
    .corretor__tabela-filtro-select:last-child>select
        width 250px
    .corretor__usuario
        bottom 4rem
    .corretor__container
        grid-template-columns 1fr
        justify-content center
        gap 4rem
    .corretor__login-form :first-child
        flex auto
    .corretor__nossos-corretores__list
        justify-content flex-start
@media $tablet-medium
    .corretor__tabela-filtro-select
        flex-direction column
        align-items flex-start
    .corretor__tabela-filtro
        align-items flex-start
        gap 1rem

@media $mobile-large
    .corretor__tabela
        padding 2rem 1rem
    .corretor__tabela-main
        place-items center start
    .corretor__header-titulo
        font-size 2rem
    .corretor__login-form, .corretor__login-registro, .corretor__secadastre-form-two-col, .corretor__secadastre-registro
        flex-direction column
    
    .corretor__login, .corretor__pasta-online, .corretor__secadastre
        width 90%
        &-titulo
            font-size 2rem
        &-descricao
            width 100%
    .corretor__nossos-corretores__list
        flex-direction column
        flex-wrap nowrap
        align-items flex-start

    .corretor__nossos-corretores__titulo
        font-size 2rem
    .corretor__login .btn, .corretor__pasta-online .btn
        width 100%
    .corretor__login-registro>p, .corretor__secadastre-registro>p
        font-size 1rem
    .corretor__tabela-head
        display none
    .corretor__tabela-item
        grid-template-columns repeat(2, auto)
        grid-template-rows repeat(3, 1fr)
    .corretor__tabela-item :nth-last-child(1), .corretor__tabela-item :nth-last-child(2)
        grid-row 3
    .corretor__tabela-item :nth-last-child(3)
        grid-row 2

		